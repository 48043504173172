import React, { useState, useEffect, useRef, useContext, useCallback } from 'react';
import { searchUnsplashPhotos } from '../../utils/unsplashUtils';
import { supabase } from '../../supabaseClient';
import { ThemeContext } from '../../ThemeContext';
import { AuthContext } from '../../AuthContext';

const BannerImageSearch = ({ onBannerSelect }) => {
  const [query, setQuery] = useState('');
  const [photos, setPhotos] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const { isDarkMode } = useContext(ThemeContext);
  const { user } = useContext(AuthContext);
  const lastQuery = useRef('');
  const lastPage = useRef(1);
  const containerRef = useRef(null);

  const fetchPhotos = useCallback(async (searchQuery, pageNum) => {
    if (isLoading || !searchQuery || pageNum <= lastPage.current) return;
    setIsLoading(true);

    try {
      const results = await searchUnsplashPhotos(searchQuery, pageNum);
      if (results.length === 0) {
        setHasMore(false);
      } else {
        setHasMore(true);
        setPhotos(prevPhotos => {
          const allPhotos = [...prevPhotos, ...results];
          const uniquePhotos = Array.from(new Set(allPhotos.map(p => p.id)))
            .map(id => allPhotos.find(p => p.id === id));
          return uniquePhotos;
        });
        lastPage.current = pageNum;
      }
    } catch (error) {
      console.error('Error fetching photos:', error);
    } finally {
      setIsLoading(false);
    }
  }, [isLoading]);

  const handleScroll = useCallback(() => {
    if (containerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
      if (scrollHeight - scrollTop <= clientHeight * 1.5 && hasMore && !isLoading) {
        setPage(prev => prev + 1);
      }
    }
  }, [hasMore, isLoading]);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
      return () => container.removeEventListener('scroll', handleScroll);
    }
  }, [handleScroll]);

  useEffect(() => {
    if (query && query !== lastQuery.current) {
      const timeoutId = setTimeout(() => {
        lastQuery.current = query;
        setPage(1);
        lastPage.current = 0;
        setPhotos([]);
        setHasMore(true);
        fetchPhotos(query, 1);
      }, 500);
      return () => clearTimeout(timeoutId);
    }
  }, [query, fetchPhotos]);

  useEffect(() => {
    if (page > 1 && query) {
      fetchPhotos(query, page);
    }
  }, [page, query, fetchPhotos]);

  const handleQueryChange = (e) => {
    const newQuery = e.target.value;
    setQuery(newQuery);
  };

  const handleSelect = async (photo) => {
    if (user) {
      const { error } = await supabase
        .from('users')
        .update({ coverPhoto: photo.urls.full })
        .eq('id', user.id);

      if (error) {
        console.error('Error updating cover photo:', error);
      } else {
        onBannerSelect(photo.urls.full);
      }
    }
  };

  return (
    <div className={`banner-image-search ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
      <form onSubmit={(e) => e.preventDefault()} className="mb-4 flex">
        <input
          type="text"
          value={query}
          onChange={handleQueryChange}
          placeholder="Search for banner images..."
          className={`flex-grow p-2 rounded-l ${isDarkMode ? 'bg-gray-800 text-white' : 'bg-white text-gray-900'}`}
        />
        <button 
          type="submit" 
          className={`p-2 rounded-r ${isDarkMode ? 'bg-blue-600 text-white' : 'bg-blue-500 text-white'}`}
        >
          Search
        </button>
      </form>
      <div ref={containerRef} className="grid grid-cols-2 gap-4 overflow-y-auto h-96">
        {photos.map((photo, index) => (
          <div key={`${photo.id}-${index}`} className="photo cursor-pointer" onClick={() => handleSelect(photo)}>
            <img src={photo.urls.thumb} alt={photo.description} className="rounded shadow-md" />
          </div>
        ))}
        {isLoading && <p>Loading...</p>}
      </div>
    </div>
  );
};

export default BannerImageSearch;
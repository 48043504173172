import React, { useRef, useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler } from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler, zoomPlugin);

const WeightChart = ({ entries, goalWeight, isDarkMode }) => {
  const chartRef = useRef(null);
  const [timeframe, setTimeframe] = useState('all');

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear().toString().substr(-2)}`;
  };

  const filterEntriesByTimeframe = (entries, timeframe) => {
    const now = new Date();
    const timeframeDays = {
      '7d': 7,
      '14d': 14,
      '30d': 30,
      '90d': 90,
      'all': Infinity
    };
    const days = timeframeDays[timeframe];
    return entries.filter(entry => {
      const entryDate = new Date(entry.inject_date || entry.weigh_date);
      return (now - entryDate) / (1000 * 60 * 60 * 24) <= days;
    });
  };

  const sortedEntries = filterEntriesByTimeframe(
    entries.sort((a, b) => new Date(a.inject_date || a.weigh_date) - new Date(b.inject_date || b.weigh_date)),
    timeframe
  );

  // Calculate min and max weights for y-axis
  const weights = sortedEntries.map(entry => entry.weight);
  const minWeight = Math.min(...weights, goalWeight);
  const maxWeight = Math.max(...weights, goalWeight);
  
  // Calculate the range and add a small buffer (5% of the range)
  const weightRange = maxWeight - minWeight;
  const buffer = weightRange * 0.05;
  
  const yAxisMin = Math.floor((minWeight - buffer) / 5) * 5; // Round down to nearest 5
  const yAxisMax = Math.ceil((maxWeight + buffer) / 5) * 5;  // Round up to nearest 5

  useEffect(() => {
    const chart = chartRef.current;

    if (chart) {
      const ctx = chart.ctx;
      const gradient = ctx.createLinearGradient(0, 0, chart.width, 0);
      gradient.addColorStop(0, 'rgba(75, 192, 192, 0.6)');
      gradient.addColorStop(1, 'rgba(75, 192, 192, 0.1)');

      chart.data.datasets[0].backgroundColor = gradient;
      chart.update();
    }
  }, [timeframe]);

  const chartData = {
    labels: sortedEntries.map(entry => formatDate(entry.inject_date || entry.weigh_date)),
    datasets: [
      {
        label: 'Weight',
        data: sortedEntries.map(entry => entry.weight),
        borderColor: 'rgb(75, 192, 192)',
        tension: 0.4,
        borderWidth: 2,
        pointRadius: 3,
        pointHoverRadius: 5,
        fill: true,
      },
      {
        label: 'Goal Weight',
        data: Array(sortedEntries.length).fill(goalWeight),
        borderColor: 'rgba(255, 99, 132, 0.8)',
        borderDash: [10, 5],
        pointRadius: 0,
        borderWidth: 2,
        fill: false,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      tooltip: {
        enabled: true,
        mode: 'nearest',
        intersect: false,
        position: 'nearest',
        backgroundColor: isDarkMode ? 'rgba(0, 0, 0, 0.8)' : 'rgba(255, 255, 255, 0.8)',
        titleColor: isDarkMode ? '#fff' : '#000',
        bodyColor: isDarkMode ? '#fff' : '#000',
        borderColor: isDarkMode ? 'rgba(255, 255, 255, 0.2)' : 'rgba(0, 0, 0, 0.2)',
        borderWidth: 1,
        padding: 10,
        cornerRadius: 5,
        displayColors: false,
        callbacks: {
          title: function(tooltipItems) {
            return formatDate(tooltipItems[0].label);
          },
          label: function (context) {
            if (context.dataset.label === 'Weight') {
              return `Weight: ${context.parsed.y} lbs`;
            }
            return null;  // This will hide the tooltip for the goal weight line
          },
        },
      },
      zoom: {
        zoom: {
          wheel: {
            enabled: true,
          },
          pinch: {
            enabled: window.innerWidth > 768,
          },
          mode: 'x',
        },
        pan: {
          enabled: window.innerWidth > 768,
          mode: 'x',
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          color: isDarkMode ? 'rgba(255, 255, 255, 0.6)' : 'rgba(0, 0, 0, 0.6)',
          maxRotation: 0,
          autoSkip: true,
          maxTicksLimit: 6,
        },
      },
      y: {
        min: yAxisMin,
        max: yAxisMax,
        grid: {
          color: isDarkMode ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)',
        },
        ticks: {
          color: isDarkMode ? 'rgba(255, 255, 255, 0.6)' : 'rgba(0, 0, 0, 0.6)',
          padding: 10,
          stepSize: 5, // Ensure ticks are every 5 units
          callback: function(value) {
            return value + ' lbs';
          },
        },
      },
    },
    elements: {
      point: {
        radius: 3,
        hoverRadius: 5,
        hitRadius: 10,
      },
    },
    interaction: {
      mode: 'nearest',
      axis: 'x',
      intersect: false,
    },
    hover: {
      mode: 'nearest',
      intersect: false,
    },
  };

  return (
    <div className="mt-6 relative">
      <div className="absolute top-0 right-0 z-0">
        <select
          value={timeframe}
          onChange={(e) => setTimeframe(e.target.value)}
          className={`text-xs p-1 rounded ${isDarkMode ? 'bg-gray-700 text-white' : 'bg-white text-black'} border ${isDarkMode ? 'border-gray-600' : 'border-gray-300'}`}
        >
          <option value="7d">7d</option>
          <option value="14d">14d</option>
          <option value="30d">30d</option>
          <option value="90d">90d</option>
          <option value="all">All</option>
        </select>
      </div>
      <div style={{ height: '300px' }}>
        <Line ref={chartRef} data={chartData} options={chartOptions} />
      </div>
    </div>
  );
};

export default WeightChart;
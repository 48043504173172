import axios from 'axios';

const API_KEY = 'sk-proj-z5cnluGDMp9bhDRoNCp0T3BlbkFJoLTt46O4i4TcR5WQRp0Q';
const MODEL = 'gpt-4o';

export const generateSummary = async (currentWeight, startWeight, lastWeight, friendlyName) => {
  const totalWeightLost = +(startWeight - currentWeight).toFixed(1);
  const changeFromLast = +(lastWeight - currentWeight).toFixed(1);

  console.log('Input values:', {
    currentWeight,
    startWeight,
    lastWeight,
    friendlyName,
    totalWeightLost,
    changeFromLast
  });

  try {
    const messages = [
      {
        role: 'system',
        content: 'You are a health and wellness coach specializing in GLP-1 Medications like Mounjaro, Zepbound, Wegovy, and Ozempic. Your name is Coach, and you provide concise, motivational summaries (50 words max) based on weight progress. Always mention if the weight has gone up, down, or stayed the same since the last weigh-in.',
      },
      {
        role: 'user',
        content: `${friendlyName}, your current weight is ${currentWeight} lbs. Start weight was ${startWeight} lbs. Last weight was ${lastWeight} lbs. Total lost: ${totalWeightLost} lbs. Change from last: ${changeFromLast} lbs. Provide a motivational summary.`,
      },
    ];

    console.log('Messages being sent to API:', JSON.stringify(messages, null, 2));

    const response = await axios.post(
      'https://api.openai.com/v1/chat/completions',
      {
        model: MODEL,
        messages: messages,
        temperature: 0.7,
      },
      {
        headers: {
          'Authorization': `Bearer ${API_KEY}`,
          'Content-Type': 'application/json',
        },
      }
    );

    console.log('API Response:', JSON.stringify(response.data, null, 2));

    const generatedContent = response.data.choices[0].message.content.trim();
    console.log('Generated content:', generatedContent);

    return generatedContent;
  } catch (error) {
    console.error('Error generating AI summary:', error);
    if (error.response) {
      console.error('Error response:', error.response.data);
    }
    // Return a default message instead of null
    return `${friendlyName}, you're currently at ${currentWeight} lbs. Keep up the good work and stay consistent!`;
  }
};
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faArrowDown, faArrowUp, faFlagCheckered } from '@fortawesome/free-solid-svg-icons';
import BMIComponent from './BMIComponent';

const StatsSection = ({ startWeight, latestEntry, goalWeight, lossStats, userHeight, isDarkMode, bmi }) => {
  const latestWeight = latestEntry?.weight || null;

  const formatWeight = (weight) => weight !== null ? `${weight.toFixed(1)} lbs` : <FontAwesomeIcon icon={faInfoCircle} />;

  const calculatePercentage = (current, start) => {
    if (current === null || start === null) return null;
    const percentage = ((start - current) / start) * 100;
    return percentage.toFixed(1);
  };

  const renderWeightChange = (change, label) => {
    if (change === null) return <FontAwesomeIcon icon={faInfoCircle} />;
    const isGain = change < 0;
    const absoluteChange = Math.abs(change);
    const percentage = calculatePercentage(latestWeight, startWeight);
    const icon = isGain ? faArrowUp : faArrowDown;
    const colorClass = isGain ? 'text-red-400' : 'text-green-400';

    return (
      <div className="flex flex-col items-center">
        <div className="flex items-center space-x-2">
          <div className={`text-xl font-bold ${isDarkMode ? 'text-white' : 'text-gray-800'}`}>
            {formatWeight(absoluteChange)}
          </div>
          {percentage !== null && (
            <span className={`text-sm ${parseFloat(percentage) < 0 ? 'text-red-400' : 'text-green-400'}`}>
              ({isGain ? '+' : '-'}{Math.abs(percentage)}%)
            </span>
          )}
        </div>
        <div className={`text-xs ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
          <FontAwesomeIcon icon={icon} className={`mr-1 ${colorClass}`} />
          {isGain ? 'up' : label}
        </div>
      </div>
    );
  };

  const renderGoal = () => {
    if (!latestWeight || !goalWeight) return <FontAwesomeIcon icon={faInfoCircle} />;
    const remainingToGoal = latestWeight - goalWeight;
    const isGoalMet = remainingToGoal <= 0;
    const flagColor = isGoalMet ? 'text-green-400' : 'text-yellow-400';

    return (
      <div className="flex flex-col items-center">
        <div className={`text-xl font-bold ${isDarkMode ? 'text-white' : 'text-gray-800'}`}>
          {isGoalMet ? 'Goal met!' : formatWeight(remainingToGoal)}
        </div>
        <div className={`text-xs ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
          <FontAwesomeIcon icon={faFlagCheckered} className={`mr-1 ${flagColor}`} />
          goal
        </div>
      </div>
    );
  };

  return (
    <div className="mb-4 text-center">
      <div className={`${isDarkMode ? 'bg-[#1E2132]' : 'bg-white'} p-4 rounded shadow mb-4`}>
        <BMIComponent bmi={bmi} isDarkMode={isDarkMode} />
        <div className="flex items-center justify-center space-x-8">
          {renderWeightChange(startWeight - latestWeight, 'down')}
          {renderGoal()}
        </div>
      </div>

      <div className="flex justify-center space-x-8">
        <div className={`${isDarkMode ? 'bg-[#1E2132]' : 'bg-white'} p-4 rounded shadow`}>
          <div className={`text-xl font-bold ${isDarkMode ? 'text-white' : 'text-gray-800'}`}>
            {formatWeight(startWeight)}
          </div>
          <div className={`${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>starting weight</div>
        </div>
        <div className={`${isDarkMode ? 'bg-[#1E2132]' : 'bg-white'} p-4 rounded shadow`}>
          <div className={`text-xl font-bold ${isDarkMode ? 'text-white' : 'text-gray-800'}`}>
            {formatWeight(latestWeight)}
          </div>
          <div className={`${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>latest weight</div>
        </div>
      </div>

      <div className="grid grid-cols-3 gap-2 mb-6 text-center mt-4">
        <div className="p-2">
          <div className={`text-lg font-bold ${isDarkMode ? 'text-white' : 'text-gray-800'}`}>
            {formatWeight(lossStats.sinceLastWeighIn)}
          </div>
          <div className="text-blue-400 text-sm">since last weigh</div>
        </div>
        <div className="p-2">
          <div className={`text-lg font-bold ${isDarkMode ? 'text-white' : 'text-gray-800'}`}>
            {formatWeight(lossStats.thirtyDays)}
          </div>
          <div className="text-orange-400 text-sm">lost in 30 days</div>
        </div>
        <div className="p-2">
          <div className={`text-lg font-bold ${isDarkMode ? 'text-white' : 'text-gray-800'}`}>
            {formatWeight(lossStats.ninetyDays)}
          </div>
          <div className="text-green-400 text-sm">lost in 90 days</div>
        </div>
      </div>
    </div>
  );
};

export default StatsSection;
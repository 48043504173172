import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Capacitor } from '@capacitor/core';
import { StatusBar } from '@capacitor/status-bar';

const initializeCapacitor = async () => {
  if (Capacitor.isNativePlatform()) {
    try {
      await StatusBar.setOverlaysWebView({ overlay: false });
      const statusBarHeight = await StatusBar.getHeight();
      document.documentElement.style.setProperty('--status-bar-height', `${statusBarHeight.height}px`);
    } catch (error) {
      console.error('Error initializing StatusBar:', error);
    }
  }
};

initializeCapacitor().then(() => {
  const root = ReactDOM.createRoot(document.getElementById('root'));
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
import React, { useState, useEffect, useContext } from 'react';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faChevronLeft, faCalendar } from '@fortawesome/free-solid-svg-icons';
import { format } from 'date-fns';
import { supabase } from '../../supabaseClient';
import { AuthContext } from '../../AuthContext';

Modal.setAppElement('#root');

const AddEntryModal = ({ isOpen, onClose, onSubmit, medications, injectionLocations, isDarkMode }) => {
  const { user } = useContext(AuthContext);
  const [trackingType, setTrackingType] = useState('');
  const [newEntry, setNewEntry] = useState({
    medication_name: '',
    inject_date: new Date(),
    dosage: '',
    weight: '',
    injection_location: '',
    weigh_date: new Date(),
    feedPost: true,
  });
  const [dosages, setDosages] = useState([]);
  const [isFormFilled, setIsFormFilled] = useState(false);
  const [lastWeight, setLastWeight] = useState(null);
  const [isTrackingGlp1, setIsTrackingGlp1] = useState(false);

  useEffect(() => {
    const checkGlp1Tracking = async () => {
      if (user) {
        const { data, error } = await supabase
          .from('users')
          .select('is_tracking_glp1')
          .eq('id', user.id)
          .single();

        if (error) {
          console.error('Error checking GLP-1 tracking status:', error);
        } else {
          setIsTrackingGlp1(data.is_tracking_glp1);
        }
      }
    };

    checkGlp1Tracking();
  }, [user]);

  const fetchDosages = async (medicationName, lastDosage = null) => {
    const { data: medicationData, error: medicationError } = await supabase
      .from('medications')
      .select('id, name')
      .eq('name', medicationName)
      .single();
  
    if (medicationError) {
      return;
    }
  
    const { data: dosageData, error: dosageError } = await supabase
      .from('dosages')
      .select('*')
      .eq('medication_id', medicationData.id)
      .eq('is_active', true)
      .order('value');
  
    if (dosageError) {
      console.error('Error fetching dosages:', dosageError);
    } else {
      setDosages(dosageData);
      if (dosageData.length > 0) {
        const dosageToSet = lastDosage || dosageData[0].value;
        setNewEntry(prev => ({ ...prev, dosage: dosageToSet }));
      }
    }
  };

  const fetchLastEntry = async () => {
    if (!user) return;

    const { data: lastMedicationEntry, error: medicationError } = await supabase
      .from('glp1_tracking')
      .select('*')
      .eq('user_id', user.id)
      .order('inject_date', { ascending: false })
      .limit(1)
      .single();
  
    const { data: lastWeightEntry, error: weightError } = await supabase
      .from('weight_tracking')
      .select('*')
      .eq('user_id', user.id)
      .order('weigh_date', { ascending: false })
      .limit(1)
      .single();
  
    if (medicationError && medicationError.code !== 'PGRST116') {
      console.error('Error fetching last medication entry:', medicationError);
    }
    if (weightError && weightError.code !== 'PGRST116') {
      console.error('Error fetching last weight entry:', weightError);
    }
  
    const newEntryData = {
      medication_name: lastMedicationEntry?.medication_name || medications[0]?.name || '',
      inject_date: new Date(),
      dosage: lastMedicationEntry?.dosage || '',
      weight: '',
      injection_location: lastMedicationEntry?.injection_location || injectionLocations[0]?.name || '',
      weigh_date: new Date(),
      feedPost: true,
    };
  
    setNewEntry(newEntryData);
    setLastWeight(lastWeightEntry?.weight || null);
  
    if (newEntryData.medication_name) {
      await fetchDosages(newEntryData.medication_name, lastMedicationEntry?.dosage);
    }
  };

  useEffect(() => {
    if (isOpen && !isFormFilled && user) {
      fetchLastEntry();
      setIsFormFilled(true);
    }
  }, [isOpen, isFormFilled, user]);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setNewEntry(prev => {
      const updatedEntry = { ...prev, [name]: type === 'checkbox' ? checked : value };
      if (name === 'inject_date' || name === 'weigh_date') {
        updatedEntry[name] = new Date(value + 'T00:00:00');
      } else if (name === 'medication_name') {
        updatedEntry.dosage = '';
        fetchDosages(value);
      }
      return updatedEntry;
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await onSubmit(trackingType, newEntry, lastWeight);
      onClose();
      setIsFormFilled(false);
      window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to top after submission
    } catch (error) {
      console.error('Error submitting entry:', error);
    }
  };

  const handleBackToSelection = () => {
    setTrackingType('');
    setNewEntry({
      medication_name: '',
      inject_date: new Date(),
      dosage: '',
      weight: '',
      injection_location: '',
      weigh_date: new Date(),
      feedPost: true,
    });
    setIsFormFilled(false);
  };
  
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Add Entry"
      className={`${isDarkMode ? 'bg-gray-900' : 'bg-gray-100'} p-6 rounded-lg shadow-lg w-full max-w-3xl mx-auto mt-20 ${isDarkMode ? 'text-white' : 'text-gray-900'} relative`}
      overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-start pt-20"
    >
      <div className="w-full">
        <div className="flex justify-between items-center mb-6">
          {trackingType && (
            <button
              onClick={handleBackToSelection}
              className={`text-2xl ${isDarkMode ? 'text-white' : 'text-gray-900'}`}
            >
              <FontAwesomeIcon icon={faChevronLeft} />
            </button>
          )}
          <h2 className={`text-2xl font-bold ${isDarkMode ? 'text-white' : 'text-gray-900'} flex-grow text-center`}>
            Add New Entry
          </h2>
          <button onClick={onClose} className={`text-2xl ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>

        {!trackingType && (
          <div className="space-y-4">
            {isTrackingGlp1 && (
              <button
                onClick={() => setTrackingType('medication')}
                className={`w-full py-3 rounded-full font-bold transition duration-300 ${
                  isDarkMode
                    ? 'bg-blue-600 text-white hover:bg-blue-700'
                    : 'bg-blue-500 text-white hover:bg-blue-600'
                }`}
              >
                Weight + Medication
              </button>
            )}
            <button
              onClick={() => setTrackingType('weight')}
              className={`w-full py-3 rounded-full font-bold transition duration-300 ${
                isDarkMode
                  ? 'bg-green-600 text-white hover:bg-green-700'
                  : 'bg-green-500 text-white hover:bg-green-600'
              }`}
            >
              Weight
            </button>
          </div>
        )}

        {trackingType && (
          <form onSubmit={handleSubmit} className="space-y-4">
            {trackingType === 'medication' && (
              <>
                <select
                  name="medication_name"
                  value={newEntry.medication_name}
                  onChange={handleInputChange}
                  className={`w-full p-3 rounded-full border focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                    isDarkMode 
                      ? 'bg-gray-800 text-white border-gray-700' 
                      : 'bg-white text-gray-900 border-gray-300'
                  }`}
                >
                  <option value="">Select Medication</option>
                  {medications.map(med => (
                    <option key={med.id} value={med.name}>{med.name}</option>
                  ))}
                </select>

                <select
                  name="dosage"
                  value={newEntry.dosage}
                  onChange={handleInputChange}
                  className={`w-full p-3 rounded-full border focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                    isDarkMode 
                      ? 'bg-gray-800 text-white border-gray-700' 
                      : 'bg-white text-gray-900 border-gray-300'
                  }`}
                >
                  <option value="">Select Dosage</option>
                  {dosages.map(dosage => (
                    <option key={dosage.id} value={dosage.value}>{dosage.value} mg</option>
                  ))}
                </select>

                <select
                  name="injection_location"
                  value={newEntry.injection_location}
                  onChange={handleInputChange}
                  className={`w-full p-3 rounded-full border focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                    isDarkMode 
                      ? 'bg-gray-800 text-white border-gray-700' 
                      : 'bg-white text-gray-900 border-gray-300'
                  }`}
                >
                  <option value="">Select Injection Location</option>
                  {injectionLocations.map(location => (
                    <option key={location.id} value={location.name}>{location.name}</option>
                  ))}
                </select>
              </>
            )}

            <input
              type="number"
              name="weight"
              value={newEntry.weight}
              onChange={handleInputChange}
              placeholder="Weight (lbs)"
              className={`w-full p-3 rounded-full border focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                isDarkMode 
                  ? 'bg-gray-800 text-white border-gray-700' 
                  : 'bg-white text-gray-900 border-gray-300'
              }`}
            />

            <div className="relative">
              <input
                type="date"
                name={trackingType === 'medication' ? 'inject_date' : 'weigh_date'}
                value={format(trackingType === 'medication' ? newEntry.inject_date : newEntry.weigh_date, 'yyyy-MM-dd')}
                onChange={handleInputChange}
                className={`w-full p-3 pl-10 rounded-full border focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                  isDarkMode 
                    ? 'bg-gray-800 text-white border-gray-700' 
                    : 'bg-white text-gray-900 border-gray-300'
                }`}
              />
              <FontAwesomeIcon
                icon={faCalendar}
                className={`absolute left-3 top-1/2 transform -translate-y-1/2 ${
                  isDarkMode ? 'text-gray-400' : 'text-gray-600'
                }`}
              />
            </div>

            <div className="flex items-center mt-4">
              <input
                type="checkbox"
                name="feedPost"
                checked={newEntry.feedPost}
                onChange={handleInputChange}
                className="mr-2"
              />
              <label htmlFor="feedPost" className="text-gray-600">Feed Post</label>
            </div>

            <button 
              type="submit" 
              className={`w-full py-3 rounded-full font-bold transition duration-300 ${
                isDarkMode
                  ? 'bg-blue-600 text-white hover:bg-blue-700'
                  : 'bg-blue-500 text-white hover:bg-blue-600'
              }`}
            >
              Add Entry
            </button>
          </form>
        )}
      </div>
    </Modal>
  );
};

export default AddEntryModal;
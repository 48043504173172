import React, { useContext, useCallback, useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faWeight, faDumbbell, faCog } from '@fortawesome/free-solid-svg-icons';
import { AuthContext } from '../AuthContext';
import { ThemeContext } from '../ThemeContext';
import { supabase } from '../supabaseClient';

const BottomNavigation = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const { isDarkMode } = useContext(ThemeContext);
  const [isVisible, setIsVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  const fetchUsername = useCallback(async (userId) => {
    try {
      const { data, error } = await supabase
        .from('users')
        .select('username')
        .eq('id', userId)
        .single();

      if (error) throw error;
      return data.username;
    } catch (error) {
      console.error('Error fetching username:', error);
      return null;
    }
  }, []);

  const handleHomeClick = async (e) => {
    e.preventDefault();
    let username = user?.username;

    if (!username && user?.id) {
      username = await fetchUsername(user.id);
    }

    if (username) {
      navigate(`/social/${username}`);
    } else {
      console.error('Username not available');
      navigate('/social/dan');
    }
  };

  const navItems = [
    { id: 'home', path: '#', icon: faHome, onClick: handleHomeClick },
    { id: 'weight', path: '/glptracker', icon: faWeight },
    { id: 'exercise', path: '/exercise', icon: faDumbbell },
    { id: 'settings', path: '/settings', icon: faCog },
  ];

  const handleScroll = useCallback(() => {
    const currentScrollY = window.scrollY;

    if (currentScrollY > lastScrollY) {
      setIsVisible(false);
    } else {
      setIsVisible(true);
    }

    setLastScrollY(currentScrollY);
  }, [lastScrollY]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  return (
    <nav className={`fixed bottom-0 left-0 right-0 transition-transform duration-300 ${
      isDarkMode ? 'bg-gray-800 text-white' : 'bg-white text-black'
    } shadow-lg ${isVisible ? 'transform translate-y-0' : 'transform translate-y-full'}`}>
      <ul className="flex justify-around items-center h-12"> {/* Reduced height to h-12 */}
        {navItems.map((item) => (
          <li key={item.id}>
            <Link
              to={item.path}
              onClick={item.onClick}
              className={`flex items-center justify-center w-12 h-12 ${
                (location.pathname === item.path || 
                 (item.id === 'home' && location.pathname.startsWith('/social/')))
                  ? 'text-blue-500'
                  : isDarkMode ? 'text-gray-400' : 'text-gray-500'
              }`}
            >
              <FontAwesomeIcon icon={item.icon} className="text-xl" /> {/* Increased icon size to text-xl */}
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default BottomNavigation;
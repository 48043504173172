import React, { useState, useContext, useEffect } from 'react';
import { supabase } from '../supabaseClient';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import googleLogo from '../img/google.svg';
import { faArrowLeft, faExclamationCircle, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import logoSvg from '../img/logo_main.svg';
import { ThemeContext } from '../ThemeContext';
import { AuthContext } from '../AuthContext';
import Onboarding from './Onboarding';

export default function Auth() {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [isSignUp, setIsSignUp] = useState(false);
  const [isProfileComplete, setIsProfileComplete] = useState(false);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const { isDarkMode } = useContext(ThemeContext);
  const { user, setUser } = useContext(AuthContext);

  useEffect(() => {
    const checkSession = async () => {
      const { data: { session } } = await supabase.auth.getSession();
      if (session) {
        setUser(session.user);
        await checkProfileCompletion(session.user.id);
      }
    };

    checkSession();

    const { data: authListener } = supabase.auth.onAuthStateChange(async (event, session) => {
      if (event === 'SIGNED_IN') {
        setUser(session.user);
        await checkProfileCompletion(session.user.id);
      } else if (event === 'SIGNED_OUT') {
        setUser(null);
        setIsProfileComplete(false);
      }
    });

    return () => {
      if (authListener && authListener.unsubscribe) {
        authListener.unsubscribe();
      }
    };
  }, [setUser]);

  const checkProfileCompletion = async (userId) => {
    try {
      const { data, error } = await supabase
        .from('users')
        .select('is_complete')
        .eq('id', userId)
        .single();
      
      if (error) throw error;
      setIsProfileComplete(data?.is_complete || false);
    } catch (error) {
      console.error('Error checking profile completion:', error);
      setIsProfileComplete(false);
    }
  };

  const handleOnboardingComplete = async () => {
    try {
      const { error } = await supabase
        .from('users')
        .update({ is_complete: true })
        .eq('id', user.id);

      if (error) throw error;

      setIsProfileComplete(true);
    } catch (error) {
      console.error('Error updating profile completion status:', error);
      setError('An error occurred while completing your profile. Please try again.');
    }
  };

  const handleOTPLogin = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      setError('');
      const { error } = await supabase.auth.signInWithOtp({ 
        email,
        options: {
          emailRedirectTo: window.location.origin
        }
      });
      if (error) throw error;
      setSuccessMessage('Check your email for the login link!');
    } catch (error) {
      setError(error.error_description || error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleGoogleLogin = async () => {
    try {
      setLoading(true);
      setError('');
      const { error } = await supabase.auth.signInWithOAuth({ provider: 'google' });
      if (error) throw error;
    } catch (error) {
      setError(error.error_description || error.message);
    } finally {
      setLoading(false);
    }
  };

  const MessageDisplay = ({ message, type }) => (
    <div className={`flex items-center justify-center p-3 rounded-md mb-4 ${
      type === 'error' 
        ? (isDarkMode ? 'bg-red-900 text-red-200' : 'bg-red-100 text-red-700')
        : (isDarkMode ? 'bg-green-900 text-green-200' : 'bg-green-100 text-green-700')
    }`}>
      <FontAwesomeIcon icon={type === 'error' ? faExclamationCircle : faCheckCircle} className="mr-2" />
      {message}
    </div>
  );

  if (user && !isProfileComplete) {
    return <Onboarding onComplete={handleOnboardingComplete} />;
  }

  if (user && isProfileComplete) {
    return <div>Main App (Replace this with your main app component)</div>;
  }

  return (
    <div className={`flex flex-col justify-center items-center min-h-screen ${isDarkMode ? 'bg-gray-900' : 'bg-gray-100'}`}>
      <div className="w-full max-w-md p-6">
        <div className="flex justify-between items-center mb-8">
          {isSignUp && (
            <button 
              onClick={() => {
                setIsSignUp(false);
                setError('');
                setSuccessMessage('');
              }}
              className={`text-2xl ${isDarkMode ? 'text-white' : 'text-gray-900'}`}
            >
              <FontAwesomeIcon icon={faArrowLeft} />
            </button>
          )}
          <img src={logoSvg} alt="Logo" className="w-24 h-24 mx-auto" />
          {isSignUp && <div className="w-8"></div>}
        </div>
        
        <div className="text-center">
          <h1 className={`text-3xl font-bold mb-8 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
            {isSignUp ? 'Create Your Account' : 'Welcome Back'}
          </h1>

          {error && <MessageDisplay message={error} type="error" />}
          {successMessage && <MessageDisplay message={successMessage} type="success" />}

          <div className="space-y-4">
            {!isSignUp && (
              <>
                <button 
                  onClick={handleGoogleLogin}
                  className={`w-full py-3 rounded-full font-bold flex items-center justify-center transition duration-300 ${
                    isDarkMode 
                      ? 'bg-gray-800 text-white border border-gray-700 hover:bg-gray-700' 
                      : 'bg-white text-gray-700 border border-gray-300 hover:bg-gray-50'
                  }`}
                >
                  <img src={googleLogo} alt="Google logo" className="mr-2 h-5" />
                  Continue with Google
                </button>

                <div className="my-4 flex items-center">
                  <hr className={`flex-grow ${isDarkMode ? 'border-gray-700' : 'border-gray-300'}`} />
                  <span className={`px-3 ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>or</span>
                  <hr className={`flex-grow ${isDarkMode ? 'border-gray-700' : 'border-gray-300'}`} />
                </div>
              </>
            )}

            <form onSubmit={handleOTPLogin} className="space-y-4">
              <input
                className={`w-full p-3 rounded-full border focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                  isDarkMode 
                    ? 'bg-gray-800 text-white border-gray-700' 
                    : 'bg-white text-gray-900 border-gray-300'
                }`}
                type="email"
                placeholder="Email address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />

              <button 
                type="submit"
                className={`w-full py-3 rounded-full font-bold transition duration-300 ${
                  isDarkMode
                    ? 'bg-blue-600 text-white hover:bg-blue-700'
                    : 'bg-blue-500 text-white hover:bg-blue-600'
                }`}
                disabled={loading}
              >
                {loading ? 'Sending...' : (isSignUp ? 'Sign Up' : 'Sign In')}
              </button>
            </form>
          </div>

          <p className={`mt-6 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
            {isSignUp ? "Already have an account? " : "Don't have an account? "}
            <button 
              type="button"
              className="text-blue-500 hover:underline bg-transparent border-none cursor-pointer font-bold"
              onClick={() => {
                setIsSignUp(!isSignUp);
                setError('');
                setSuccessMessage('');
              }}
            >
              {isSignUp ? "Log in" : "Sign up"}
            </button>
          </p>
        </div>
      </div>
    </div>
  );
}
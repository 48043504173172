import { supabase, profilePicturesBucket } from '../supabaseClient';

export const fetchProfileImage = async (imagePath) => {
  try {
    const { data, error } = await supabase.storage
      .from(profilePicturesBucket)
      .download(imagePath);

    if (error) throw error;

    return URL.createObjectURL(data);
  } catch (error) {
    console.error('Error fetching image URL:', error.message);
    return 'images/default-avatar.png'; // Fallback image
  }
};

import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faBars, faUserCircle, faSpinner, faWeight, faDumbbell } from '@fortawesome/free-solid-svg-icons';
import GlpTracker from './components/WeightTracker/WeightTracker';
import Settings from './components/Settings';
import Auth from './components/Auth';
import Onboarding from './components/Onboarding';
import { supabase, profilePicturesBucket } from './supabaseClient';
import { ThemeProvider, ThemeContext } from './ThemeContext';
import { AuthProvider, AuthContext } from './AuthContext';
import { PostProvider } from './PostContext';
import logoLight from './img/logo_light.svg';
import logoDark from './img/logo_dark.svg';
import UserProfile from './components/Social/UserProfile';
import BottomNavigation from './components/BottomNavigation';
import ExerciseTracker from './components/ExerciseTracker/ExerciseTracker';
import ActiveWorkout from './components/ExerciseTracker/ActiveWorkout';
import WorkoutHistory from './components/ExerciseTracker/WorkoutHistory';
import { LocalNotifications } from '@capacitor/local-notifications';
import { Capacitor } from '@capacitor/core';
import { Preferences } from '@capacitor/preferences';

const UserProfileWrapper = () => {
  const { username } = useParams();
  return <UserProfile key={username} />;
};

const setupApiConfig = async () => {
  if (Capacitor.isNativePlatform()) {
    await Preferences.set({
      key: 'API_URL',
      value: 'https://sea-turtle-app-23w29.ondigitalocean.app/api'
    });
    await Preferences.set({
      key: 'API_KEY',
      value: '7XZqkL1PSlo44ppY443'
    });
  }
};

const initializeLocalNotifications = async () => {
  try {
    const permissionStatus = await LocalNotifications.checkPermissions();
    if (permissionStatus.display !== 'granted') {
      await LocalNotifications.requestPermissions();
      console.log('LocalNotifications permissions granted');
    }
  } catch (error) {
    console.error('Error setting up LocalNotifications:', error);
  }
};

const AppContent = () => {
  const { user, loading: authLoading } = useContext(AuthContext);
  const [isProfileComplete, setIsProfileComplete] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { isDarkMode } = useContext(ThemeContext);
  const [menuOpen, setMenuOpen] = useState(false);
  const [menuStyle, setMenuStyle] = useState({ transform: 'translateX(-100%)' });
  const navigate = useNavigate();
  const touchStartXRef = useRef(0);
  const currentTranslateXRef = useRef(-100);
  const [profileImage, setProfileImage] = useState(null);
  const [username, setUsername] = useState(null);

  useEffect(() => {
    initializeLocalNotifications();
    setupApiConfig();
  }, []);

  const fetchProfileImage = useCallback(async (imagePath) => {
    try {
      const { data, error } = await supabase.storage
        .from(profilePicturesBucket)
        .download(imagePath);

      if (error) throw error;

      const url = URL.createObjectURL(data);
      setProfileImage(url);
    } catch (error) {
      console.error('Error fetching image URL:', error.message);
      setProfileImage(null);
    }
  }, []);

  const fetchUserProfile = useCallback(async (userId) => {
    const { data, error } = await supabase
      .from('users')
      .select('image_link, username')
      .eq('id', userId)
      .single();

    if (error) {
      console.error('Error fetching user profile:', error);
    } else {
      if (data.image_link) {
        fetchProfileImage(data.image_link);
      }
      setUsername(data.username);
    }
  }, [fetchProfileImage]);

  const checkProfileCompletion = useCallback(async (userId) => {
    const { data, error } = await supabase
      .from('users')
      .select('is_complete')
      .eq('id', userId)
      .single();

    if (error) {
      console.error('Error checking profile completion:', error);
      setIsProfileComplete(false);
    } else {
      setIsProfileComplete(data.is_complete);
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (user) {
      fetchUserProfile(user.id);
      checkProfileCompletion(user.id);
    } else if (!authLoading) {
      setIsLoading(false);
    }
  }, [user, authLoading, fetchUserProfile, checkProfileCompletion]);

  useEffect(() => {
    if (username && isProfileComplete && window.location.pathname === '/') {
      navigate(`/social/${username}`);
    }
  }, [username, isProfileComplete, navigate]);

  useEffect(() => {
    document.documentElement.classList.toggle('dark-theme', isDarkMode);
    document.documentElement.classList.toggle('light-theme', !isDarkMode);
  }, [isDarkMode]);

  const handleLogout = async () => {
    await supabase.auth.signOut();
  };

  const handleTouchStart = (e) => {
    touchStartXRef.current = e.changedTouches[0].screenX;
    setMenuStyle({ ...menuStyle, transition: 'none' });
  };

  const handleTouchMove = (e) => {
    const touchMoveX = e.changedTouches[0].screenX;
    const translateX = Math.min(0, Math.max(-100, currentTranslateXRef.current + ((touchMoveX - touchStartXRef.current) / window.innerWidth) * 100));
    setMenuStyle({ transform: `translateX(${translateX}%)` });
  };

  const handleTouchEnd = (e) => {
    const touchEndX = e.changedTouches[0].screenX;
    const translateX = currentTranslateXRef.current + ((touchEndX - touchStartXRef.current) / window.innerWidth) * 100;
    if (translateX < -25) {
      closeMenu();
    } else {
      openMenu();
    }
  };

  const openMenu = () => {
    setMenuOpen(true);
    currentTranslateXRef.current = 0;
    setMenuStyle({ transform: 'translateX(0%)', transition: 'transform 0.3s ease' });
  };

  const closeMenu = () => {
    setMenuOpen(false);
    currentTranslateXRef.current = -100;
    setMenuStyle({ transform: 'translateX(-100%)', transition: 'transform 0.3s ease' });
  };

  const handleProfileUpdate = async () => {
    if (user) {
      await fetchUserProfile(user.id);
    }
  };

  if (isLoading || authLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <FontAwesomeIcon icon={faSpinner} spin size="2x" color={isDarkMode ? '#ffffff' : '#000000'} />
      </div>
    );
  }

  if (!user) {
    return <Auth />;
  }

  if (!isProfileComplete) {
    return <Onboarding user={user} onComplete={() => setIsProfileComplete(true)} />;
  }

  return (
    <div className={`${isDarkMode ? 'bg-[#131827] text-white' : 'bg-white text-black'} min-h-screen text-sm relative`}>
      <header className={`flex items-center p-3 ${isDarkMode ? 'border-gray-600' : 'border-gray-300'} border-b relative`}>
        <button onClick={openMenu} className="text-2xl">
          <FontAwesomeIcon icon={faBars} />
        </button>
        <img src={isDarkMode ? logoLight : logoDark} alt="Logo" className="h-8 mx-3" />
        <div className="ml-auto flex items-center space-x-4">
          {profileImage ? (
            <img
              src={profileImage}
              alt="Profile"
              className="w-8 h-8 rounded-full cursor-pointer"
              onClick={() => navigate(`/social/${username}`)}
            />
          ) : (
            <FontAwesomeIcon
              icon={faUserCircle}
              size="2x"
              className="cursor-pointer"
              onClick={() => navigate(`/social/${username}`)}
            />
          )}
        </div>
      </header>
      {menuOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-40"
          onClick={closeMenu}
        />
      )}
      <nav
        className={`fixed left-0 top-0 ${isDarkMode ? 'bg-gray-800 text-white' : 'bg-gray-100 text-black'} w-64 h-full z-50 flex flex-col`}
        style={menuStyle}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        <div className="p-4">
          <div className="text-sm font-bold">Main Menu</div>
        </div>
        <Link to="/glptracker" className="p-4 flex items-center" onClick={closeMenu}>
          <FontAwesomeIcon icon={faWeight} className="mr-2" />
          Weight Tracking
        </Link>
        <Link to="/exercise" className={`p-4 flex items-center ${isDarkMode ? 'border-b border-gray-700' : 'border-b border-gray-200'}`} onClick={closeMenu}>
          <FontAwesomeIcon icon={faDumbbell} className="mr-2" />
          Exercise
        </Link>
        <div className={`p-4 ${isDarkMode ? 'border-t border-gray-700' : 'border-t border-gray-200'}`}>
          <div className="text-sm font-bold">Account</div>
        </div>
        <button onClick={handleLogout} className="p-4 flex items-center w-full text-left">
          <FontAwesomeIcon icon={faSignOutAlt} className="mr-2" />
          Log Out
        </button>
      </nav>
      <div className="p-3 pb-20">
        <Routes>
          <Route path="/" element={<GlpTracker />} />
          <Route path="/glptracker" element={<GlpTracker />} />
          <Route path="/social/:username" element={<UserProfileWrapper />} />
          <Route path="/settings" element={<Settings onProfileUpdate={handleProfileUpdate} />} />
          <Route path="/exercise" element={<ExerciseTracker />} />
          <Route path="/workout/:workoutId" element={<ActiveWorkout />} />
          <Route path="/workout-history" element={<WorkoutHistory />} />
        </Routes>
      </div>
      <BottomNavigation />
    </div>
  );
};

const App = () => {
  return (
    <Router>
      <ThemeProvider>
        <AuthProvider>
          <PostProvider>
            <div style={{
              paddingTop: 'var(--status-bar-height, 0px)',
              minHeight: '100vh',
              display: 'flex',
              flexDirection: 'column'
            }}>
              <AppContent />
            </div>
          </PostProvider>
        </AuthProvider>
      </ThemeProvider>
    </Router>
  );
};

export default App;
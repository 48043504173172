import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase, profilePicturesBucket } from '../supabaseClient';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import '../styles/datepicker-custom.css';
import { ThemeContext } from '../ThemeContext';
import { AuthContext } from '../AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle, faChevronLeft, faSpinner, faUserCircle, faTrash, faUser, faWeight } from '@fortawesome/free-solid-svg-icons';
import Cropper from 'react-easy-crop';
import { getCroppedImg } from '../utils/cropImage';
import { setupGlp1Notification, cancelGlp1Notification, checkNotificationPermissions } from '../utils/notificationUtils';

const Settings = ({ onProfileUpdate }) => {
  const navigate = useNavigate();
  const { isDarkMode, toggleTheme } = useContext(ThemeContext);
  const { user: authUser } = useContext(AuthContext);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({
    friendly_name: '',
    date_of_birth: null,
    heightFeet: '',
    heightInches: '',
    start_weight: '',
    goal_weight: '',
    sex: '',
    location: '',
    tagline: '',
    is_tracking_glp1: false,
    glp1_tracking_day: '',
  });
  const [profileUpdateSuccess, setProfileUpdateSuccess] = useState(false);
  const [error, setError] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [profileImage, setProfileImage] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [cropping, setCropping] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const [hasNotificationPermissions, setHasNotificationPermissions] = useState(false);

  const fetchProfileImage = useCallback(async (imagePath) => {
    try {
      const { data, error } = await supabase.storage
        .from(profilePicturesBucket)
        .download(imagePath);

      if (error) throw error;

      const url = URL.createObjectURL(data);
      setProfileImage(url);
    } catch (error) {
      console.error('Error fetching image URL:', error.message);
      setProfileImage(null);
      setError('Failed to load profile image.');
    }
  }, []);

  const fetchUserData = useCallback(async () => {
    try {
      if (!authUser) {
        throw new Error('No authenticated user found');
      }

      const { data, error } = await supabase
        .from('users')
        .select('*')
        .eq('id', authUser.id)
        .single();

      if (error) throw error;

      const heightInches = data.height || 0;
      const heightFeet = Math.floor(heightInches / 12);
      const heightInchesRemainder = heightInches % 12;

      setUser(data);
      setFormData({
        friendly_name: data.friendly_name || '',
        date_of_birth: data.date_of_birth ? new Date(data.date_of_birth) : null,
        heightFeet: heightFeet.toString(),
        heightInches: heightInchesRemainder.toString(),
        start_weight: data.start_weight || '',
        goal_weight: data.goal_weight || '',
        sex: data.sex || '',
        location: data.location || '',
        tagline: data.tagline || '',
        is_tracking_glp1: data.is_tracking_glp1 || false,
        glp1_tracking_day: data.glp1_tracking_day || '',
      });

      if (data.image_link) {
        await fetchProfileImage(data.image_link);
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
      setError('Failed to fetch user data. Please try again.');
    } finally {
      setLoading(false);
    }
  }, [authUser, fetchProfileImage]);

  useEffect(() => {
    if (authUser) {
      fetchUserData();
    } else {
      setLoading(false);
    }
  }, [authUser, fetchUserData]);

  useEffect(() => {
    const checkPermissions = async () => {
      const hasPermissions = await checkNotificationPermissions();
      setHasNotificationPermissions(hasPermissions);
    };
    checkPermissions();
  }, []);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prev => ({ 
      ...prev, 
      [name]: type === 'checkbox' ? checked : value 
    }));
  };

  const handleDateChange = (date) => {
    setFormData(prev => ({ ...prev, date_of_birth: date }));
  };

  const handleImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setImageFile(e.target.files[0]);
      setImageUrl(URL.createObjectURL(e.target.files[0]));
      setCropping(true);
    }
  };

  const handleCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleSaveCrop = async () => {
    setIsUploading(true);
    try {
      const croppedImage = await getCroppedImg(imageUrl, croppedAreaPixels);
      await uploadImage(croppedImage);
      onProfileUpdate();
    } catch (e) {
      console.error('Error cropping image:', e);
      setError('Failed to crop and upload image.');
    } finally {
      setIsUploading(false);
      setCropping(false);
      setImageFile(null);
    }
  };

  const uploadImage = async (file) => {
    const fileExt = file.name.split('.').pop();
    const fileName = `${user.id}-${Math.random().toString(36).substring(2)}.${fileExt}`;
    const filePath = `profile-pictures/${fileName}`;

    try {
      const { error } = await supabase.storage
        .from(profilePicturesBucket)
        .upload(filePath, file);

      if (error) throw error;

      await supabase
        .from('users')
        .update({ image_link: filePath })
        .eq('id', user.id);

      fetchProfileImage(filePath);
    } catch (error) {
      console.error('Error uploading image:', error);
      throw error;
    }
  };

  const deleteProfileImage = async () => {
    try {
      await supabase
        .from('users')
        .update({ image_link: null })
        .eq('id', user.id);

      setProfileImage(null);
      onProfileUpdate();
    } catch (error) {
      console.error('Error deleting profile image:', error);
      setError('Failed to delete profile image.');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setIsUploading(true);

    try {
      const totalHeightInches = (parseInt(formData.heightFeet) * 12) + parseInt(formData.heightInches);
      const { error } = await supabase
        .from('users')
        .update({
          friendly_name: formData.friendly_name,
          date_of_birth: formData.date_of_birth,
          height: totalHeightInches,
          start_weight: formData.start_weight,
          goal_weight: formData.goal_weight,
          sex: formData.sex,
          location: formData.location,
          tagline: formData.tagline,
          is_tracking_glp1: formData.is_tracking_glp1,
          glp1_tracking_day: formData.is_tracking_glp1 ? formData.glp1_tracking_day : null,
        })
        .eq('id', user.id);

      if (error) throw error;

      if (formData.is_tracking_glp1 && hasNotificationPermissions) {
        const notificationSet = await setupGlp1Notification(formData.glp1_tracking_day);
        if (!notificationSet) {
          console.warn('Failed to set up GLP-1 notification');
        }
      } else if (!formData.is_tracking_glp1) {
        await cancelGlp1Notification();
      }

      setProfileUpdateSuccess(true);
      setTimeout(() => setProfileUpdateSuccess(false), 3000);
    } catch (error) {
      console.error('Error updating profile:', error);
      setError('Failed to update profile. Please try again.');
    } finally {
      setIsUploading(false);
    }
  };

  if (loading) {
    return <div className={`flex justify-center items-center h-screen ${isDarkMode ? 'bg-gray-900 text-white' : 'bg-white text-gray-900'}`}>Loading...</div>;
  }

  if (!authUser) {
    return <div className={`flex justify-center items-center h-screen ${isDarkMode ? 'bg-gray-900 text-white' : 'bg-white text-gray-900'}`}>Please log in to view settings.</div>;
  }

  return (
    <div className={`flex flex-col min-h-screen ${isDarkMode ? 'bg-gray-900' : 'bg-white'}`}>
      <div className="w-full max-w-md p-6 mx-auto relative">
        <button 
          onClick={() => navigate('/')}
          className={`absolute top-6 left-6 text-2xl ${isDarkMode ? 'text-white' : 'text-gray-800'}`}
        >
          <FontAwesomeIcon icon={faChevronLeft} />
        </button>

        <h2 className={`text-3xl font-bold mb-8 text-center ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
          User Settings
        </h2>
        
        {error && (
          <div className={`p-4 mb-4 rounded-md ${isDarkMode ? 'bg-red-900 text-red-200' : 'bg-red-100 text-red-700'}`}>
            <FontAwesomeIcon icon={faExclamationCircle} className="mr-2" />
            {error}
          </div>
        )}

        {profileUpdateSuccess && (
          <div className={`p-4 mb-4 rounded-md ${isDarkMode ? 'bg-green-900 text-green-200' : 'bg-green-100 text-green-700'}`}>
            Profile updated successfully!
          </div>
        )}

        <div className="flex flex-col items-center space-y-2 mb-8">
          {profileImage ? (
            <img 
              src={profileImage} 
              alt="Profile" 
              className="w-20 h-20 rounded-full object-cover mb-2 cursor-pointer" 
              onClick={() => document.getElementById('profile-picture').click()} 
            />
          ) : (
            <FontAwesomeIcon 
              icon={faUserCircle} 
              size="3x" 
              className="cursor-pointer" 
              onClick={() => document.getElementById('profile-picture').click()} 
            />
          )}
          <label htmlFor="profile-picture" className="w-full text-center">
            <input
              type="file"
              id="profile-picture"
              accept="image/*"
              onChange={handleImageChange}
              className="hidden"
            />
          </label>
          {imageFile && cropping && (
            <div className="fixed inset-0 bg-black bg-opacity-75 z-50 flex flex-col items-center justify-center p-4">
              <div className="relative w-full max-w-lg bg-white p-4 rounded shadow-lg flex flex-col items-center justify-center">
                <div className="relative w-full h-96">
                  <Cropper
                    image={imageUrl}
                    crop={crop}
                    zoom={zoom}
                    aspect={1}
                    onCropChange={setCrop}
                    onCropComplete={handleCropComplete}
                    onZoomChange={setZoom}
                  />
                </div>
                <button 
                  className="mt-4 py-2 px-4 bg-blue-500 text-white rounded"
                  onClick={handleSaveCrop}
                  disabled={isUploading}
                >
                  {isUploading ? <FontAwesomeIcon icon={faSpinner} spin /> : 'Save Crop'}
                </button>
              </div>
            </div>
          )}
          <button 
            type="button" 
            className="text-red-500" 
            onClick={deleteProfileImage}
          >
            <FontAwesomeIcon icon={faTrash} />
          </button>
        </div>
        
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="flex items-center space-x-2">
            <FontAwesomeIcon icon={faUser} className={`${isDarkMode ? 'text-white' : 'text-gray-900'}`} />
            <span className={`text-xl font-semibold ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>Profile</span>
          </div>
          <input
            type="text"
            name="friendly_name"
            value={formData.friendly_name}
            onChange={handleInputChange}
            placeholder="Friendly Name"
            className={`w-full p-3 rounded-full border focus:outline-none focus:ring-2 focus:ring-blue-500 ${
              isDarkMode 
                ? 'bg-gray-800 text-white border-gray-700' 
                : 'bg-white text-gray-900 border-gray-300'
            }`}
          />
          
          <DatePicker
            selected={formData.date_of_birth}
            onChange={handleDateChange}
            placeholderText="Date of Birth"
            className={`w-full p-3 rounded-full border focus:outline-none focus:ring-2 focus:ring-blue-500 ${
              isDarkMode 
                ? 'bg-gray-800 text-white border-gray-700' 
                : 'bg-white text-gray-900 border-gray-300'
            }`}
          />
          
          <div className="flex space-x-2">
            <input
              type="number"
              name="heightFeet"
              value={formData.heightFeet}
              onChange={handleInputChange}
              placeholder="Height (feet)"
              className={`w-1/2 p-3 rounded-full border focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                isDarkMode 
                  ? 'bg-gray-800 text-white border-gray-700' 
                  : 'bg-white text-gray-900 border-gray-300'
              }`}
            />
            <input
              type="number"
              name="heightInches"
              value={formData.heightInches}
              onChange={handleInputChange}
              placeholder="Height (inches)"
              className={`w-1/2 p-3 rounded-full border focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                isDarkMode 
                  ? 'bg-gray-800 text-white border-gray-700' 
                  : 'bg-white text-gray-900 border-gray-300'
              }`}
            />
          </div>
          
          <input
            type="number"
            name="start_weight"
            value={formData.start_weight}
            onChange={handleInputChange}
            placeholder="Start Weight (lbs)"
            className={`w-full p-3 rounded-full border focus:outline-none focus:ring-2 focus:ring-blue-500 ${
              isDarkMode 
                ? 'bg-gray-800 text-white border-gray-700' 
                : 'bg-white text-gray-900 border-gray-300'
            }`}
          />
            
          <input
            type="number"
            name="goal_weight"
            value={formData.goal_weight}
            onChange={handleInputChange}
            placeholder="Goal Weight (lbs)"
            className={`w-full p-3 rounded-full border focus:outline-none focus:ring-2 focus:ring-blue-500 ${
              isDarkMode 
                ? 'bg-gray-800 text-white border-gray-700' 
                : 'bg-white text-gray-900 border-gray-300'
            }`}
          />
            
          <select
            name="sex"
            value={formData.sex}
            onChange={handleInputChange}
            className={`w-full p-3 rounded-full border focus:outline-none focus:ring-2 focus:ring-blue-500 ${
              isDarkMode 
                ? 'bg-gray-800 text-white border-gray-700' 
                : 'bg-white text-gray-900 border-gray-300'
            }`}
          >
            <option value="" disabled>Select Sex</option>
            <option value="male">Male</option>
            <option value="female">Female</option>
            <option value="other">Other</option>
          </select>

          <input
            type="text"
            name="location"
            value={formData.location}
            onChange={handleInputChange}
            placeholder="Location"
            className={`w-full p-3 rounded-full border focus:outline-none focus:ring-2 focus:ring-blue-500 ${
              isDarkMode 
                ? 'bg-gray-800 text-white border-gray-700' 
                : 'bg-white text-gray-900 border-gray-300'
            }`}
          />

          <input
            type="text"
            name="tagline"
            value={formData.tagline}
            onChange={handleInputChange}
            placeholder="Tagline"
            className={`w-full p-3 rounded-full border focus:outline-none focus:ring-2 focus:ring-blue-500 ${
              isDarkMode 
                ? 'bg-gray-800 text-white border-gray-700' 
                : 'bg-white text-gray-900 border-gray-300'
            }`}
          />

          <hr className={`my-6 ${isDarkMode ? 'border-gray-700' : 'border-gray-200'}`} />

          <div className="flex items-center space-x-2">
            <FontAwesomeIcon icon={faWeight} className={`${isDarkMode ? 'text-white' : 'text-gray-900'}`} />
            <span className={`text-xl font-semibold ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>Weight Management</span>
          </div>
          <div className={`flex items-center ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
            <input
              type="checkbox"
              name="is_tracking_glp1"
              checked={formData.is_tracking_glp1}
              onChange={handleInputChange}
              className="mr-2"
            />
            <label htmlFor="is_tracking_glp1">Track GLP-1 weight loss medications</label>
          </div>

          {formData.is_tracking_glp1 && (
            <select
              name="glp1_tracking_day"
              value={formData.glp1_tracking_day}
              onChange={handleInputChange}
              className={`w-full p-3 rounded-full border focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                isDarkMode 
                  ? 'bg-gray-800 text-white border-gray-700' 
                  : 'bg-white text-gray-900 border-gray-300'
              }`}
            >
              <option value="" disabled>Select Tracking Day</option>
              {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map(day => (
                <option key={day} value={day}>{day}</option>
              ))}
            </select>
          )}

          {formData.is_tracking_glp1 && !hasNotificationPermissions && (
            <div className={`p-4 mb-4 rounded-md ${isDarkMode ? 'bg-yellow-900 text-yellow-200' : 'bg-yellow-100 text-yellow-700'}`}>
              Notifications are not enabled. Please enable notifications in your device settings to receive GLP-1 reminders.
            </div>
          )}
            
          <button 
            type="submit" 
            className={`w-full py-3 rounded-full font-bold transition duration-300 ${
              isDarkMode
                ? 'bg-blue-600 text-white hover:bg-blue-700'
                : 'bg-blue-500 text-white hover:bg-blue-600'
            }`}
            disabled={isUploading}
          >
            {isUploading ? <FontAwesomeIcon icon={faSpinner} spin /> : 'Save Changes'}
          </button>
        </form>

        <div className="mt-8 flex items-center justify-between">
          <span className={`${isDarkMode ? 'text-white' : 'text-gray-700'} font-medium`}>
            Dark Mode
          </span>
          <label className="flex items-center cursor-pointer">
            <div className="relative">
              <input type="checkbox" className="sr-only" checked={isDarkMode} onChange={toggleTheme} />
              <div className={`block ${isDarkMode ? 'bg-blue-600' : 'bg-gray-600'} w-14 h-8 rounded-full`}></div>
              <div className={`dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition ${isDarkMode ? 'transform translate-x-full' : ''}`}></div>
            </div>
          </label>
        </div>
      </div>
    </div>
  );
};

export default Settings;
// src/PostContext.js
import React, { createContext, useState, useContext } from 'react';

const PostContext = createContext();

export const usePostContext = () => useContext(PostContext);

export const PostProvider = ({ children }) => {
  const [deletedPosts, setDeletedPosts] = useState([]);

  const addDeletedPost = (postId) => {
    setDeletedPosts(prev => [...prev, postId]);
  };

  return (
    <PostContext.Provider value={{ deletedPosts, addDeletedPost }}>
      {children}
    </PostContext.Provider>
  );
};
import React, { useState, useContext, useEffect } from 'react';
import { supabase } from '../supabaseClient';
import { ThemeContext } from '../ThemeContext';
import { AuthContext } from '../AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import logoSvg from '../img/logo_main.svg';

export default function Onboarding({ onComplete }) {
  const { isDarkMode } = useContext(ThemeContext);
  const { user } = useContext(AuthContext);
  const [friendlyName, setFriendlyName] = useState('');
  const [dobMonth, setDobMonth] = useState('');
  const [dobDay, setDobDay] = useState('');
  const [dobYear, setDobYear] = useState('');
  const [heightFeet, setHeightFeet] = useState('');
  const [heightInches, setHeightInches] = useState('');
  const [startWeight, setStartWeight] = useState('');
  const [goalWeight, setGoalWeight] = useState('');
  const [sex, setSex] = useState('');
  const [isTrackingGlp1, setIsTrackingGlp1] = useState(false);
  const [glp1TrackingDay, setGlp1TrackingDay] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!user || !user.email) {
      setError('Unable to load user data. Please try logging in again.');
    }
  }, [user]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!friendlyName || !dobMonth || !dobDay || !dobYear || !heightFeet || !startWeight || !goalWeight || !sex) {
      setError('Please fill in all required fields');
      return;
    }

    if (isTrackingGlp1 && !glp1TrackingDay) {
      setError('Please select a day for GLP-1 tracking');
      return;
    }

    setLoading(true);
    setError(null);

    const dateOfBirth = `${dobYear}-${dobMonth.padStart(2, '0')}-${dobDay.padStart(2, '0')}`;
    const heightInInches = (parseInt(heightFeet) * 12) + parseInt(heightInches || 0);

    try {
      const submissionData = {
        id: user.id,
        email: user.email,
        friendly_name: friendlyName,
        date_of_birth: dateOfBirth,
        height: heightInInches,
        start_weight: parseFloat(startWeight),
        goal_weight: parseFloat(goalWeight),
        sex,
        is_complete: true,
        is_tracking_glp1: isTrackingGlp1,
        glp1_tracking_day: isTrackingGlp1 ? glp1TrackingDay : null
      };

      const { data, error: supabaseError } = await supabase
        .from('users')
        .upsert(submissionData)
        .select();

      if (supabaseError) {
        throw supabaseError;
      }

      if (data && data.length > 0) {
        onComplete();
      } else {
        throw new Error('No data returned from Supabase');
      }
    } catch (error) {
      setError(error.message || 'An unexpected error occurred');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={`flex flex-col justify-center items-center min-h-screen ${isDarkMode ? 'bg-gray-900' : 'bg-gray-100'}`}>
      <div className="w-full max-w-md p-6">
        <div className="flex justify-center mb-8">
          <img src={logoSvg} alt="Logo" className="w-24 h-24" />
        </div>

        <div className="text-center">
          <h1 className={`text-3xl font-bold mb-8 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
            Complete Your Profile
          </h1>

          {error && (
            <div className={`flex items-center justify-center mb-4 ${isDarkMode ? 'text-red-200' : 'text-red-700'}`}>
              <FontAwesomeIcon icon={faExclamationCircle} className="mr-2" />
              {error}
            </div>
          )}

          <form onSubmit={handleSubmit} className="space-y-4">
            <input
              className={`w-full p-3 rounded-full border focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                isDarkMode 
                  ? 'bg-gray-800 text-white border-gray-700' 
                  : 'bg-white text-gray-900 border-gray-300'
              }`}
              type="text"
              placeholder="Full Name"
              value={friendlyName}
              onChange={(e) => setFriendlyName(e.target.value)}
              required
            />

            <div className="grid grid-cols-3 gap-2">
              <select
                className={`p-3 rounded-full border focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                  isDarkMode 
                    ? 'bg-gray-800 text-white border-gray-700' 
                    : 'bg-white text-gray-900 border-gray-300'
                }`}
                value={dobMonth}
                onChange={(e) => setDobMonth(e.target.value)}
                required
              >
                <option value="">Month</option>
                {Array.from({ length: 12 }, (_, i) => i + 1).map((month) => (
                  <option key={month} value={month.toString().padStart(2, '0')}>
                    {month}
                  </option>
                ))}
              </select>
              <select
                className={`p-3 rounded-full border focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                  isDarkMode 
                    ? 'bg-gray-800 text-white border-gray-700' 
                    : 'bg-white text-gray-900 border-gray-300'
                }`}
                value={dobDay}
                onChange={(e) => setDobDay(e.target.value)}
                required
              >
                <option value="">Day</option>
                {Array.from({ length: 31 }, (_, i) => i + 1).map((day) => (
                  <option key={day} value={day.toString().padStart(2, '0')}>
                    {day}
                  </option>
                ))}
              </select>
              <input
                className={`p-3 rounded-full border focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                  isDarkMode 
                    ? 'bg-gray-800 text-white border-gray-700' 
                    : 'bg-white text-gray-900 border-gray-300'
                }`}
                type="number"
                placeholder="Year"
                value={dobYear}
                onChange={(e) => setDobYear(e.target.value)}
                required
                min="1900"
                max={new Date().getFullYear()}
              />
            </div>

            <div className="grid grid-cols-2 gap-2">
              <input
                className={`p-3 rounded-full border focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                  isDarkMode 
                    ? 'bg-gray-800 text-white border-gray-700' 
                    : 'bg-white text-gray-900 border-gray-300'
                }`}
                type="number"
                placeholder="Height (feet)"
                value={heightFeet}
                onChange={(e) => setHeightFeet(e.target.value)}
                required
                min="1"
                max="9"
              />
              <input
                className={`p-3 rounded-full border focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                  isDarkMode 
                    ? 'bg-gray-800 text-white border-gray-700' 
                    : 'bg-white text-gray-900 border-gray-300'
                }`}
                type="number"
                placeholder="Height (inches)"
                value={heightInches}
                onChange={(e) => setHeightInches(e.target.value)}
                required
                min="0"
                max="11"
              />
            </div>

            <input
              className={`w-full p-3 rounded-full border focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                isDarkMode 
                  ? 'bg-gray-800 text-white border-gray-700' 
                  : 'bg-white text-gray-900 border-gray-300'
              }`}
              type="number"
              placeholder="Start Weight (lbs)"
              value={startWeight}
              onChange={(e) => setStartWeight(e.target.value)}
              required
              min="1"
              step="0.1"
            />

            <input
              className={`w-full p-3 rounded-full border focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                isDarkMode 
                  ? 'bg-gray-800 text-white border-gray-700' 
                  : 'bg-white text-gray-900 border-gray-300'
              }`}
              type="number"
              placeholder="Goal Weight (lbs)"
              value={goalWeight}
              onChange={(e) => setGoalWeight(e.target.value)}
              required
              min="1"
              step="0.1"
            />

            <select
              className={`w-full p-3 rounded-full border focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                isDarkMode 
                  ? 'bg-gray-800 text-white border-gray-700' 
                  : 'bg-white text-gray-900 border-gray-300'
              }`}
              value={sex}
              onChange={(e) => setSex(e.target.value)}
              required
            >
              <option value="">Select Sex</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="Other">Other</option>
            </select>

            <div className={`flex items-center ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
              <input
                type="checkbox"
                id="isTrackingGlp1"
                checked={isTrackingGlp1}
                onChange={(e) => setIsTrackingGlp1(e.target.checked)}
                className="mr-2"
              />
              <label htmlFor="isTrackingGlp1">I'm using weight management medications (e.g., GLP-1)</label>
            </div>

            {isTrackingGlp1 && (
              <select
                className={`w-full p-3 rounded-full border focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                  isDarkMode 
                    ? 'bg-gray-800 text-white border-gray-700' 
                    : 'bg-white text-gray-900 border-gray-300'
                }`}
                value={glp1TrackingDay}
                onChange={(e) => setGlp1TrackingDay(e.target.value)}
                required
              >
                <option value="">Select Medication Day</option>
                {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map(day => (
                  <option key={day} value={day}>{day}</option>
                ))}
              </select>
            )}

            <button 
              type="submit"
              className={`w-full py-3 rounded-full font-bold transition duration-300 ${
                isDarkMode
                  ? 'bg-blue-600 text-white hover:bg-blue-700'
                  : 'bg-blue-500 text-white hover:bg-blue-600'
              }`}
              disabled={loading}
            >
              {loading ? 'Submitting...' : 'Complete Profile'}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';

const BMIComponent = ({ bmi, isDarkMode }) => {
  const getBMICategory = (bmi) => {
    if (bmi < 18.5) return { category: "Underweight", color: "green-400" };
    if (bmi >= 18.5 && bmi < 25) return { category: "Healthy", color: "green-400" };
    if (bmi >= 25 && bmi < 30) return { category: "Overweight", color: "yellow-400" };
    return { category: "Obese", color: "red-500" };
  };

  const { category, color } = getBMICategory(bmi);
  const bmiPosition = bmi ? ((bmi - 15) / 20) * 300 : 0;

  return (
    <div className={`p-4 rounded text-center ${isDarkMode ? 'bg-[#1E2132]' : 'bg-white'}`}>
      <h3 className={`text-xl font-bold mb-2 ${isDarkMode ? 'text-white' : 'text-gray-800'}`}>Body Mass Index (BMI)</h3>
      <div className="flex items-center justify-center mb-4">
        <span className={`text-3xl font-bold mr-2 ${isDarkMode ? 'text-white' : 'text-gray-800'}`}>{bmi || 'N/A'}</span>
        <span className={`text-lg ${bmi ? `text-${color}` : 'text-gray-400'}`}>
          {bmi ? category : 'No Data'}
        </span>
      </div>
      <div className="relative flex justify-center mt-4">
        {bmi && (
          <FontAwesomeIcon
            icon={faCaretDown}
            className={`absolute ${isDarkMode ? 'text-white' : 'text-black'}`}
            style={{ left: `${bmiPosition}px`, transform: 'translateX(-50%)' }}
          />
        )}
        <svg width="300" height="30" viewBox="0 0 300 30">
          <defs>
            <linearGradient id="barGradient" x1="0%" y1="0%" x2="100%" y2="0%">
              <stop offset="0%" style={{ stopColor: '#13eac9', stopOpacity: 1 }} />
              <stop offset="30%" style={{ stopColor: '#66ccff', stopOpacity: 1 }} />
              <stop offset="50%" style={{ stopColor: '#f9f2b2', stopOpacity: 1 }} />
              <stop offset="70%" style={{ stopColor: '#ffa343', stopOpacity: 1 }} />
              <stop offset="100%" style={{ stopColor: '#ff6666', stopOpacity: 1 }} />
            </linearGradient>
          </defs>
          <rect x="0" y="10" width="300" height="10" fill="url(#barGradient)" rx="5" />
        </svg>
      </div>
    </div>
  );
};

export default BMIComponent;
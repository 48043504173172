import { createPost } from './getStreamApi';

export const createWeightUpdatePost = async (userId, weight, lastWeight) => {
  const weightChange = weight - lastWeight;
  const roundedWeightChange = Math.abs(Math.round(weightChange * 10) / 10); // Round to one decimal place and make positive
  const changeDirection = weightChange < 0 ? 'down' : weightChange > 0 ? 'up' : 'same';
  const changeIcon = changeDirection === 'down' ? '⬇️' :
                     changeDirection === 'up' ? '⬆️' :
                     '➡️';
  const changeText = changeDirection === 'same' ? `No change` : `${changeIcon} ${roundedWeightChange} lbs`;

  const content = `
**📊 Weight Update!**  
**💪 Current weight:** ${weight} lbs  
**${changeText} since last weigh-in.**

Keep up the great work! Stay motivated and keep pushing towards your goal! 🎯
`;

  try {
    const postData = await createPost(userId, content);
    return postData;
  } catch (error) {
    console.error('Error creating weight update post:', error);
    throw error;
  }
};

export const createWorkoutCompletionPost = async (userId, workoutData) => {


  const { id, name, duration, exercises = [], totalVolume, setCount } = workoutData;

  const formatDuration = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins}m ${secs}s`;
  };

  const content = `
**🏋️ I just completed "${name}"!** 🎉  
**⏱️ Duration:** ${formatDuration(duration)}  
**📊 Total volume:** ${Math.round(totalVolume)} lbs  
**🔢 Total sets:** ${setCount}

`;

  // Prepare a more detailed workout data object
  const detailedWorkoutData = {
    id,  // Make sure to include the id here
    name,
    duration,
    totalVolume,
    setCount,
    exercises: Array.isArray(exercises) ? exercises.map(exercise => ({
      id: exercise.id,
      name: exercise.name,
      sets: Array.isArray(exercise.sets) ? exercise.sets.map(set => ({
        set_number: set.set_number,
        weight: set.weight,
        reps: set.reps,
        is_completed: set.is_completed
      })) : []
    })) : []
  };

  try {
    const postData = await createPost(userId, content, { 
      custom_fields: { 
        workout_data: JSON.stringify(detailedWorkoutData) 
      } 
    });
    return postData;
  } catch (error) {
    console.error('Error creating workout completion post:', error);
    throw error;
  }
};

// You can add more social utility functions here as needed

export const formatPostDate = (date) => {
  const now = new Date();
  const postDate = new Date(date);
  const diffTime = Math.abs(now - postDate);
  const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
  const diffHours = Math.floor(diffTime / (1000 * 60 * 60));
  const diffMinutes = Math.floor(diffTime / (1000 * 60));

  if (diffDays > 0) {
    return `${diffDays}d ago`;
  } else if (diffHours > 0) {
    return `${diffHours}h ago`;
  } else if (diffMinutes > 0) {
    return `${diffMinutes}m ago`;
  } else {
    return 'Just now';
  }
};

export const truncateText = (text, maxLength) => {
  if (text.length <= maxLength) return text;
  return text.substr(0, maxLength) + '...';
};
import React, { useContext, useEffect, useState, useCallback, useRef, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { format, parseISO, isValid, differenceInMinutes } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
import { ThemeContext } from '../../ThemeContext';
import { AuthContext } from '../../AuthContext';
import { usePostContext } from '../../PostContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComment, faHeart, faShare, faUser, faMinus, faTimes, faDumbbell } from '@fortawesome/free-solid-svg-icons';
import CommentSection from './CommentSection';
import { fetchProfileImage } from '../../utils/imageUtils';
import { supabase } from '../../supabaseClient';
import { addReaction, removeReaction, fetchReactions, deletePost } from '../../utils/getStreamApi';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

const userCache = {};
const reactionsCache = {};

const PostCard = ({ post, onDelete, onWorkoutClick }) => {
  const { isDarkMode } = useContext(ThemeContext);
  const { user: currentUser } = useContext(AuthContext);
  const { addDeletedPost } = usePostContext();
  const [imageUrl, setImageUrl] = useState(null);
  const [showComments, setShowComments] = useState(false);
  const [commentCount, setCommentCount] = useState(null);
  const [likeCount, setLikeCount] = useState(0);
  const [hasLiked, setHasLiked] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [postUser, setPostUser] = useState({});
  const [loading, setLoading] = useState(true);
  const postRef = useRef(null);
  const [isLikeActionInProgress, setIsLikeActionInProgress] = useState(false);
  const [userReaction, setUserReaction] = useState(null);

  const actorId = useMemo(() => post?.actor?.replace('User:', ''), [post]);
  const isWorkoutPost = useMemo(() => {
    const hasWorkoutData = post.custom_fields && post.custom_fields.workout_data;
    return hasWorkoutData;
  }, [post]);

  const workoutData = useMemo(() => {
    if (isWorkoutPost) {
      try {
        const parsedData = JSON.parse(post.custom_fields.workout_data);
        return parsedData;
      } catch (error) {
        console.error('Error parsing workout data:', error);
        return null;
      }
    }
    return null;
  }, [isWorkoutPost, post]);

  useEffect(() => {
    const fetchUserDetails = async () => {
      if (actorId) {
        if (userCache[actorId]) {
          setPostUser(userCache[actorId]);
          if (userCache[actorId].imageUrl) {
            setImageUrl(userCache[actorId].imageUrl);
          }
          return;
        }

        const { data: user, error } = await supabase
          .from('users')
          .select('username, friendly_name, image_link')
          .eq('id', actorId)
          .single();

        if (error) {
          console.error('Error fetching user details:', error);
        } else {
          if (user.image_link) {
            const imgUrl = await fetchProfileImage(user.image_link);
            user.imageUrl = imgUrl;
            setImageUrl(imgUrl);
          }
          userCache[actorId] = user;
          setPostUser(user);
        }
      }
    };

    fetchUserDetails();
  }, [actorId]);

  const fetchCommentCount = useCallback(async () => {
    if (commentCount === null && post?.id) {
      if (reactionsCache[post.id]?.comment) {
        setCommentCount(reactionsCache[post.id].comment.length);
        return;
      }
      const comments = await fetchReactions(post.id, 'comment');
      reactionsCache[post.id] = {
        ...reactionsCache[post.id],
        comment: comments
      };
      setCommentCount(comments.length);
    }
  }, [post?.id, commentCount]);

  const checkIfLiked = useCallback(async () => {
    if (post?.id && currentUser) {
      try {
        const reactions = await fetchReactions(post.id, 'like');
        const userReaction = reactions.find(reaction => reaction.user_id === currentUser.id);
        setHasLiked(!!userReaction);
        setUserReaction(userReaction);
        setLikeCount(reactions.length);
        reactionsCache[post.id] = {
          ...reactionsCache[post.id],
          like: reactions
        };
      } catch (error) {
        console.error('Error checking if liked:', error);
      }
    }
  }, [post?.id, currentUser]);

  useEffect(() => {
    checkIfLiked();
  
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          fetchCommentCount();
          observer.unobserve(entry.target);
        }
      },
      { threshold: 0.1 }
    );
  
    const postElement = postRef.current;
    if (postElement) {
      observer.observe(postElement);
    }
  
    return () => {
      if (postElement) {
        observer.unobserve(postElement);
      }
    };
  }, [fetchCommentCount, checkIfLiked, post.id]);

  useEffect(() => {
    if (postUser && postUser.username) {
      setLoading(false);
    }
  }, [postUser]);

  const toggleComments = () => {
    setShowComments(!showComments);
  };

  const handleNewComment = () => {
    fetchCommentCount();
  };

  const toggleLike = async () => {
    if (isLikeActionInProgress || !post?.id || !currentUser) return;
    
    setIsLikeActionInProgress(true);
    
    try {
      if (hasLiked) {
        if (userReaction) {
          await removeReaction(userReaction.id);
        } else {
        }
        setLikeCount(prev => prev - 1);
        setHasLiked(false);
        setUserReaction(null);
      } else {

        const newReaction = await addReaction(post.id, 'like', currentUser.id, '');
        setLikeCount(prev => prev + 1);
        setHasLiked(true);
        setUserReaction(newReaction);
      }
      // Update the reactions cache
      await checkIfLiked();
    } catch (error) {
      console.error('Error toggling like:', error);
      if (error.response && error.response.status === 404) {
        await checkIfLiked();
      } else {
        alert('An error occurred while updating the like. Please try again.');
      }
    } finally {
      setTimeout(() => setIsLikeActionInProgress(false), 1000);
    }
  };

  const handleDeletePost = async () => {
    if (post?.id) {
      setIsDeleting(true);
      setTimeout(async () => {
        try {
          await deletePost(post.id, currentUser.id);
          addDeletedPost(post.id);
          onDelete(post.id);
        } catch (error) {
          console.error('Error deleting post:', error);
          setIsDeleting(false);
        }
      }, 300);
    }
  };

  const formatPostDate = (dateString, userTimeZone = 'UTC') => {
    const dateUTC = parseISO(dateString + 'Z');

    if (isValid(dateUTC)) {
      const postDateInUserTimeZone = toZonedTime(dateUTC, userTimeZone);
      const nowDateInUserTimeZone = toZonedTime(new Date(), userTimeZone);
      const diffInMinutes = Math.abs(differenceInMinutes(nowDateInUserTimeZone, postDateInUserTimeZone));

      if (diffInMinutes < 60) {
        return `${diffInMinutes} minute${diffInMinutes === 1 ? '' : 's'} ago`;
      } else if (diffInMinutes < 1440) {
        const diffInHours = Math.floor(diffInMinutes / 60);
        return `about ${diffInHours} hour${diffInHours === 1 ? '' : 's'} ago`;
      } else {
        return format(postDateInUserTimeZone, 'MMM d, yyyy h:mm a');
      }
    }
    return 'Invalid date';
  };

  const handleWorkoutClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (isWorkoutPost && workoutData) {
      onWorkoutClick(workoutData);
    } else {
      console.error('Workout data is missing');
    }
  };

  const renderPostContent = () => {
    if (!post) return null;
  
    let content = post.content || '';
  
    return (
      <div>
        <ReactMarkdown remarkPlugins={[remarkGfm]}>{content}</ReactMarkdown>
        {isWorkoutPost && (
          <button
            onClick={handleWorkoutClick}
            className={`mt-2 px-4 py-2 rounded ${isDarkMode ? 'bg-blue-600 hover:bg-blue-700' : 'bg-blue-500 hover:bg-blue-600'} text-white focus:outline-none`}
          >
            <FontAwesomeIcon icon={faDumbbell} className="mr-2" />
            View Workout
          </button>
        )}
      </div>
    );
  };  

  if (!post) {
    console.error('Invalid post data:', post);
    return null;
  }

  return (
    <div ref={postRef} className={`p-4 rounded-lg ${isDarkMode ? 'bg-gray-800 text-white' : 'bg-white text-gray-900'} shadow-lg mb-4 relative transition-transform transform ${isDeleting ? 'translate-x-full opacity-0' : 'translate-x-0 opacity-100'}`}>
      {loading ? (
        <div className="animate-pulse">
          <div className="flex items-center justify-between mb-4">
            <div className="flex items-center">
              <div className="w-10 h-10 rounded-full bg-gray-300 mr-2"></div>
              <div className="flex flex-col">
                <div className="w-24 h-4 bg-gray-300 rounded mb-2"></div>
                <div className="w-32 h-4 bg-gray-300 rounded"></div>
              </div>
            </div>
          </div>
          <div className="mb-4 w-full h-6 bg-gray-300 rounded"></div>
          <div className="flex justify-between items-center">
            <div className="flex items-center space-x-4">
              <div className="w-6 h-6 bg-gray-300 rounded"></div>
              <div className="w-6 h-6 bg-gray-300 rounded"></div>
            </div>
            <div className="w-6 h-6 bg-gray-300 rounded"></div>
          </div>
        </div>
      ) : (
        <>
          {currentUser && currentUser.id === actorId && (
            <button 
              onClick={() => setShowDeleteConfirmation(true)}
              className="absolute top-2 right-2 text-gray-500 hover:text-red-500 focus:outline-none"
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>
          )}
          <div className="flex items-center justify-between mb-4">
            <div className="flex items-center">
              {actorId ? (
                <Link to={`/social/${postUser.username}`} className="flex items-center">
                  {imageUrl ? (
                    <img
                      src={imageUrl}
                      alt={postUser.friendly_name || 'User'}
                      className="w-10 h-10 rounded-full mr-2"
                    />
                  ) : (
                    <FontAwesomeIcon icon={faUser} className="w-10 h-10 mr-2" />
                  )}
                  <div className="flex items-center">
                    <div>
                      <span className="font-bold">{postUser.friendly_name || 'Anonymous User'}</span>
                      <span className="text-sm text-gray-500 ml-1">@{postUser.username || 'anonymous'}</span>
                      <FontAwesomeIcon icon={faMinus} className="text-sm text-gray-500 mx-2" />
                      <span className="text-xs text-gray-500">
                        {formatPostDate(post.time)}
                      </span>
                    </div>
                  </div>
                </Link>
              ) : (
                <div className="flex items-center">
                  <FontAwesomeIcon icon={faUser} className="w-10 h-10 mr-2" />
                  <div className="flex items-center">
                    <div>
                      <span className="font-bold">Unknown User</span>
                      <span className="text-sm text-gray-500 ml-1">@unknown</span>
                      <FontAwesomeIcon icon={faMinus} className="text-sm text-gray-500 mx-2" />
                      <span className="text-xs text-gray-500">
                        {formatPostDate(post.time)}
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="mb-4">
            {renderPostContent()}
          </div>

          <div className="flex justify-between items-center">
            <div className="flex items-center space-x-4">
              <button onClick={toggleComments} className="flex items-center space-x-1 text-gray-500 hover:text-blue-500 focus:outline-none">
                <FontAwesomeIcon icon={faComment} />
                <span>{commentCount !== null ? commentCount : ''}</span>
              </button>
              <button 
                className={`flex items-center space-x-1 text-gray-500 focus:outline-none ${hasLiked ? 'text-red-500' : 'hover:text-red-500'}`} 
                onClick={toggleLike}
              >
                <FontAwesomeIcon icon={faHeart} />
                <span>{likeCount || 0}</span>
              </button>
            </div>
            <button className="flex items-center space-x-1 text-gray-500 hover:text-gray-700 focus:outline-none">
              <FontAwesomeIcon icon={faShare} />
              <span>Share</span>
            </button>
          </div>

          {showComments && (
            <div className={`mt-4 p-4 rounded-lg shadow-inner ${isDarkMode ? 'bg-gray-700' : 'bg-gray-100'} w-full mx-auto`}>
              <CommentSection postId={post.id} onNewComment={handleNewComment} />
            </div>
          )}

          {showDeleteConfirmation && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
              <div className={`${isDarkMode ? 'bg-gray-800 text-white' : 'bg-white text-gray-900'} p-4 rounded shadow-md`}>
                <p>Are you sure you want to delete this post?</p>
                <div className="mt-4 flex justify-end">
                  <button
                    onClick={handleDeletePost}
                    className="bg-red-500 text-white px-4 py-2 rounded mr-2"
                  >
                    Yes
                  </button>
                  <button
                    onClick={() => setShowDeleteConfirmation(false)}
                    className="bg-gray-500 text-white px-4 py-2 rounded"
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default PostCard;
import React, { useState, useEffect, useContext, useCallback, useRef } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faClock, faEllipsisV, faPlay, faPause } from '@fortawesome/free-solid-svg-icons';
import { supabase } from '../../supabaseClient';
import { AuthContext } from '../../AuthContext';
import { ThemeContext } from '../../ThemeContext';
import { createWorkoutCompletionPost } from '../../utils/socialUtils';
import ExerciseBlock from './ExerciseBlock';

const ActiveWorkout = () => {
  const { workoutId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const initialWorkout = location.state?.initialWorkout;
  const { user: currentUser } = useContext(AuthContext);
  const { isDarkMode } = useContext(ThemeContext);
  const [workoutName, setWorkoutName] = useState(initialWorkout?.name || 'New Workout');
  const [duration, setDuration] = useState(initialWorkout?.duration || 0);
  const [isTimerRunning, setIsTimerRunning] = useState(false);
  const [exercises, setExercises] = useState(initialWorkout?.workout_exercises || []);
  const [newExerciseName, setNewExerciseName] = useState('');
  const timerRef = useRef(null);

  const fetchWorkoutDetails = async () => {
    const { data: workoutData, error: workoutError } = await supabase
      .from('workouts')
      .select('name, duration')
      .eq('id', workoutId)
      .single();

    if (workoutError) {
      console.error('Error fetching workout details:', workoutError);
    } else {
      setWorkoutName(workoutData.name);
      setDuration(workoutData.duration || 0);
      // If the workout has a duration, assume it's running
      if (workoutData.duration > 0) {
        setIsTimerRunning(true);
      }
    }

    const { data: exercisesData, error: exercisesError } = await supabase
      .from('workout_exercises')
      .select(`
        id,
        name,
        workout_sets (*)
      `)
      .eq('workout_id', workoutId)
      .order('order_index');

    if (exercisesError) {
      console.error('Error fetching exercises:', exercisesError);
    } else {
      setExercises(exercisesData);
    }
  };

  useEffect(() => {
    fetchWorkoutDetails();

    const workoutChannel = supabase.channel(`workout:${workoutId}`)
      .on(
        'postgres_changes',
        { event: 'UPDATE', schema: 'public', table: 'workouts', filter: `id=eq.${workoutId}` },
        (payload) => {
          setWorkoutName(payload.new.name);
          if (payload.new.duration !== undefined) {
            setDuration(payload.new.duration);
          }
        }
      )
      .on(
        'postgres_changes',
        { event: '*', schema: 'public', table: 'workout_exercises', filter: `workout_id=eq.${workoutId}` },
        () => {
          fetchWorkoutDetails();
        }
      )
      .subscribe();

    return () => {
      supabase.removeChannel(workoutChannel);
    };
  }, [workoutId]);

  const updateDuration = useCallback(async (newDuration) => {
    setDuration(newDuration);
    await supabase
      .from('workouts')
      .update({ duration: newDuration })
      .eq('id', workoutId);
  }, [workoutId]);

  const toggleTimer = useCallback(() => {
    setIsTimerRunning(prev => !prev);
  }, []);

  useEffect(() => {
    if (isTimerRunning) {
      timerRef.current = setInterval(() => {
        setDuration(prevDuration => {
          const newDuration = prevDuration + 1;
          updateDuration(newDuration);
          return newDuration;
        });
      }, 1000);
    } else {
      clearInterval(timerRef.current);
    }

    return () => {
      clearInterval(timerRef.current);
    };
  }, [isTimerRunning, updateDuration]);

  const updateWorkoutName = useCallback(async (newName) => {
    const { error } = await supabase
      .from('workouts')
      .update({ name: newName })
      .eq('id', workoutId);

    if (error) {
      console.error('Error updating workout name:', error);
    }
  }, [workoutId]);

  const handleNameChange = (e) => {
    const newName = e.target.value;
    setWorkoutName(newName);
    updateWorkoutName(newName);
  };

  const formatDuration = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };

  const deleteExercise = async (exerciseId) => {
    try {
      // First, delete all associated sets
      const { error: setsError } = await supabase
        .from('workout_sets')
        .delete()
        .eq('workout_exercise_id', exerciseId);
  
      if (setsError) throw setsError;
  
      // Then, delete the exercise
      const { error: exerciseError } = await supabase
        .from('workout_exercises')
        .delete()
        .eq('id', exerciseId);
  
      if (exerciseError) throw exerciseError;
  
      // Update the local state to remove the deleted exercise
      setExercises(exercises.filter(exercise => exercise.id !== exerciseId));
    } catch (error) {
      console.error('Error deleting exercise:', error);
    }
  };

  const addExercise = async () => {
    if (!newExerciseName.trim()) return;

    const { data: maxOrderData, error: maxOrderError } = await supabase
      .from('workout_exercises')
      .select('order_index')
      .eq('workout_id', workoutId)
      .order('order_index', { ascending: false })
      .limit(1);

    if (maxOrderError) {
      console.error('Error fetching max order index:', maxOrderError);
      return;
    }

    const newOrderIndex = maxOrderData.length > 0 ? maxOrderData[0].order_index + 1 : 0;

    const { data, error } = await supabase
      .from('workout_exercises')
      .insert({
        workout_id: workoutId,
        exercise_id: null,
        name: newExerciseName,
        order_index: newOrderIndex
      })
      .select()
      .single();

    if (error) {
      console.error('Error adding exercise:', error);
    } else {
      setExercises([...exercises, { ...data, workout_sets: [] }]);
      setNewExerciseName('');
    }
  };

  const calculateWorkoutStats = (exercises) => {
    let totalVolume = 0;
    let setCount = 0;
  
    exercises.forEach(exercise => {
      if (exercise.workout_sets) {
        exercise.workout_sets.forEach(set => {
          if (set.weight && set.reps) {
            totalVolume += set.weight * set.reps;
            setCount++;
          }
        });
      }
    });
  
    return { totalVolume, setCount };
  };
  
  const endWorkout = async () => {
    const { data: latestExercises, error: fetchError } = await supabase
      .from('workout_exercises')
      .select(`
        id,
        name,
        workout_sets (*)
      `)
      .eq('workout_id', workoutId)
      .order('order_index');
  
    if (fetchError) {
      console.error('Error fetching latest exercise data:', fetchError);
      return;
    }
  
    setExercises(latestExercises);
  
    const { totalVolume, setCount } = calculateWorkoutStats(latestExercises);
  
    const { error } = await supabase
      .from('workouts')
      .update({ 
        end_time: new Date().toISOString(),
        duration: duration,
        total_volume: totalVolume,
        set_count: setCount
      })
      .eq('id', workoutId);
  
    if (error) {
      console.error('Error ending workout:', error);
    } else {
      const workoutData = {
        id: workoutId,
        name: workoutName,
        duration,
        exercises: latestExercises.map(exercise => ({
          id: exercise.id,
          name: exercise.name,
          sets: exercise.workout_sets || []
        })),
        totalVolume,
        setCount
      };
  
      try {
        await createWorkoutCompletionPost(currentUser.id, workoutData);
        navigate('/exercise');
      } catch (postError) {
        console.error('Error creating workout post:', postError);
      }
    }
  };

  return (
    <div className={`${isDarkMode ? 'bg-gray-900 text-white' : 'bg-white text-gray-900'} min-h-screen p-4`}>
      <div className="flex items-center justify-between mb-4">
        <button onClick={() => navigate(-1)}>
          <FontAwesomeIcon icon={faArrowLeft} />
        </button>
        <input
          type="text"
          value={workoutName}
          onChange={handleNameChange}
          className={`text-xl font-bold bg-transparent border-b ${isDarkMode ? 'border-gray-600 focus:border-white' : 'border-gray-300 focus:border-gray-900'} focus:outline-none`}
        />
        <FontAwesomeIcon icon={faEllipsisV} />
      </div>
      <div className="text-center mb-6 flex items-center justify-center">
        <FontAwesomeIcon 
          icon={isTimerRunning ? faPause : faPlay} 
          onClick={toggleTimer}
          className="mr-2 cursor-pointer"
        />
        {formatDuration(duration)}
      </div>
      {exercises.map((exercise) => (
        <ExerciseBlock 
          key={exercise.id} 
          exercise={exercise} 
          workoutId={workoutId} 
          onDeleteExercise={deleteExercise}
        />
      ))}
      <div className="mt-4">
        <input
          type="text"
          value={newExerciseName}
          onChange={(e) => setNewExerciseName(e.target.value)}
          placeholder="Enter exercise name"
          className={`w-full ${isDarkMode ? 'bg-gray-800 text-white' : 'bg-gray-100 text-gray-900'} p-2 rounded`}
        />
        <button
          onClick={addExercise}
          className="mt-2 w-full bg-blue-500 text-white py-2 rounded"
        >
          Add Exercise
        </button>
      </div>
      <button
        onClick={endWorkout}
        className="mt-4 w-full bg-red-500 text-white py-2 rounded"
      >
        End Workout
      </button>
    </div>
  );
};

export default ActiveWorkout;
import React, { useContext, useEffect, useState } from 'react';
import { ThemeContext } from '../../ThemeContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faShare, faDumbbell, faClock, faWeightHanging, faListOl } from '@fortawesome/free-solid-svg-icons';
import { format, parseISO, isValid } from 'date-fns';
import { supabase } from '../../supabaseClient';

const WorkoutDetailsModal = ({ workout, onClose }) => {
  const { isDarkMode } = useContext(ThemeContext);
  const [workoutDetails, setWorkoutDetails] = useState(null);

  useEffect(() => {
    const fetchWorkoutDetails = async () => {
      const { data, error } = await supabase
        .from('workouts')
        .select(`
          *,
          workout_exercises (
            id,
            name,
            workout_sets (
              id,
              set_number,
              weight,
              reps
            )
          )
        `)
        .eq('id', workout.id)
        .single();

      if (error) {
        console.error('Error fetching workout details:', error);
      } else {
        setWorkoutDetails(data);
      }
    };

    fetchWorkoutDetails();
  }, [workout.id]);

  const formatDate = (dateString) => {
    if (!dateString) return 'No end time provided';
    const date = parseISO(dateString);
    return isValid(date) ? format(date, 'EEEE, MMM d, yyyy - h:mma') : 'Invalid date';
  };

  const formatDuration = (seconds) => {
    const mins = Math.floor(seconds / 60);
    return `${mins}min`;
  };

  const calculateTotalVolume = (exercises) => {
    return exercises.reduce((total, exercise) => {
      return total + exercise.workout_sets.reduce((exerciseTotal, set) => {
        return exerciseTotal + (set.weight || 0) * (set.reps || 0);
      }, 0);
    }, 0);
  };

  const calculateTotalSets = (exercises) => {
    return exercises.reduce((total, exercise) => {
      return total + exercise.workout_sets.length;
    }, 0);
  };

  if (!workoutDetails) {
    return <div>Loading...</div>;
  }

  const totalVolume = calculateTotalVolume(workoutDetails.workout_exercises);
  const totalSets = calculateTotalSets(workoutDetails.workout_exercises);

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 p-4">
      <div className={`relative w-full max-w-2xl h-full max-h-[90vh] ${isDarkMode ? 'bg-gray-800 text-white' : 'bg-white text-gray-900'} rounded-lg shadow-xl overflow-hidden flex flex-col`}>
        {/* Header */}
        <div className="flex justify-between items-center p-4">
          <h2 className="text-xl font-semibold">{workoutDetails.name}</h2>
          <div className="flex items-center space-x-2">
            <button className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200">
              <FontAwesomeIcon icon={faShare} />
            </button>
            <button onClick={onClose} className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200">
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
        </div>

        {/* Content */}
        <div className="flex-1 overflow-auto p-4">
          {/* Date and time */}
          <div className="mb-4">
            <p className="text-sm text-gray-500 dark:text-gray-400">
              Completed on: {formatDate(workoutDetails.end_time)}
            </p>
          </div>

          {/* Key stats */}
          <div className="grid grid-cols-3 gap-4 mb-6">
            <div className="p-4 rounded-lg shadow-md flex items-center justify-center">
              <FontAwesomeIcon icon={faClock} className="text-blue-500 mr-2" />
              <p className="text-lg font-semibold">{formatDuration(workoutDetails.duration)}</p>
            </div>
            <div className="p-4 rounded-lg shadow-md flex items-center justify-center">
              <FontAwesomeIcon icon={faWeightHanging} className="text-blue-500 mr-2" />
              <p className="text-lg font-semibold">{totalVolume} lbs</p>
            </div>
            <div className="p-4 rounded-lg shadow-md flex items-center justify-center">
              <FontAwesomeIcon icon={faListOl} className="text-blue-500 mr-2" />
              <p className="text-lg font-semibold">{totalSets}</p>
            </div>
          </div>

        {/* Exercises */}
        {workoutDetails.workout_exercises && workoutDetails.workout_exercises.length > 0 ? (
          workoutDetails.workout_exercises.map((exercise) => (
            <div key={exercise.id} className="mb-4 rounded-lg shadow-md overflow-hidden">
              <div className={`${isDarkMode ? 'bg-gray-700' : 'bg-gray-100'} p-3 flex items-center`}>
                <FontAwesomeIcon icon={faDumbbell} className="mr-2 text-blue-500" />
                <h5 className="text-lg font-semibold">{exercise.name}</h5>
              </div>
              <div className={`p-4 ${isDarkMode ? 'bg-gray-800' : 'bg-white'}`}>
                <table className="w-full">
                  <thead>
                    <tr className="text-left text-gray-500 dark:text-gray-400">
                      <th className="pb-2 text-center">SET</th>
                      <th className="pb-2 text-center">REPS</th>
                      <th className="pb-2 text-center">WEIGHT</th>
                    </tr>
                  </thead>
                  <tbody>
                    {exercise.workout_sets && exercise.workout_sets.length > 0 ? (
                      exercise.workout_sets.map((set) => (
                        <tr key={set.id}>
                          <td className="py-1 text-center">{set.set_number}</td>
                          <td className="py-1 text-center">{set.reps}</td>
                          <td className="py-1 text-center">{set.weight}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="3" className="py-1 text-center text-gray-500">No sets available</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          ))
        ) : (
          <p className="text-gray-500">No exercises available</p>
        )}
        </div>
        </div>
        </div>
        );
        };

export default WorkoutDetailsModal;
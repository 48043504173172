import React, { useState, useEffect, useContext, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import Confetti from 'react-confetti';
import useWindowSize from 'react-use/lib/useWindowSize';
import { ThemeContext } from '../../ThemeContext';
import { AuthContext } from '../../AuthContext'; 
import { supabase } from '../../supabaseClient';
import { generateSummary } from '../../coachApi';
import StatsSection from './StatsSection';
import WeightChart from './WeightChart';
import EntriesTable from './EntriesTable';
import AddEntryModal from './AddEntryModal';
import WeightSkeletonLoader from './WeightSkeletonLoader';
import { fetchUserProfile, fetchMedications, fetchInjectionLocations, calculateStats } from '../../utils/utils';
import { createWeightUpdatePost } from '../../utils/socialUtils';

const GlpTracker = () => {
  const { isDarkMode } = useContext(ThemeContext);
  const { user } = useContext(AuthContext);
  const { width, height } = useWindowSize();
  const [medicationEntries, setMedicationEntries] = useState([]);
  const [weightEntries, setWeightEntries] = useState([]);
  const [combinedEntries, setCombinedEntries] = useState([]);
  const [medications, setMedications] = useState([]);
  const [injectionLocations, setInjectionLocations] = useState([]);
  const [startWeight, setStartWeight] = useState(0);
  const [goalWeight, setGoalWeight] = useState(0);
  const [friendlyName, setFriendlyName] = useState('');
  const [coachMessage, setCoachMessage] = useState('');
  const [showConfetti, setShowConfetti] = useState(false);
  const [confettiMessage, setConfettiMessage] = useState('');
  const [specialMessage, setSpecialMessage] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [latestEntry, setLatestEntry] = useState(null);
  const [lossStats, setLossStats] = useState({ sinceLastWeighIn: null, thirtyDays: null, ninetyDays: null, allTime: null });
  const [userHeight, setUserHeight] = useState(null);
  const [halfwayMilestoneReached, setHalfwayMilestoneReached] = useState(false);
  const [bmi, setBmi] = useState(null);
  const [defaultView, setDefaultView] = useState('medication');
  const [isLoading, setIsLoading] = useState(true);
  const [navVisible, setNavVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0); // Define lastScrollY and setLastScrollY

  const fetchMedicationEntries = useCallback(async () => {
    if (!user) return;
    const { data, error } = await supabase
      .from('glp1_tracking')
      .select('*')
      .eq('user_id', user.id)
      .order('inject_date', { ascending: false });

    if (error) {
      console.error('error fetching medication entries', error);
    } else {
      setMedicationEntries(data);
    }
  }, [user]);

  const fetchWeightEntries = useCallback(async () => {
    if (!user) return;
    const { data, error } = await supabase
      .from('weight_tracking')
      .select('*')
      .eq('user_id', user.id)
      .order('weigh_date', { ascending: false });

    if (error) {
      console.error('error fetching weight entries', error);
    } else {
      setWeightEntries(data);
    }
  }, [user]);

  const combineAndSortEntries = useCallback(() => {
    const combined = [
      ...medicationEntries.map(entry => ({ ...entry, type: 'medication', date: entry.inject_date })),
      ...weightEntries.map(entry => ({ ...entry, type: 'weight', date: entry.weigh_date }))
    ];

    const sorted = combined.sort((a, b) => new Date(b.date) - new Date(a.date));
    setCombinedEntries(sorted);

    if (sorted.length > 0) {
      setLatestEntry(sorted[0]);
    }

    const stats = calculateStats(sorted);
    setLossStats(stats);
    
    const halfGoal = (startWeight - goalWeight) / 2;
    const hasReachedHalfway = sorted.some(entry => startWeight - entry.weight >= halfGoal);
    setHalfwayMilestoneReached(hasReachedHalfway);

    if (medicationEntries.length > 0 && weightEntries.length > 0) {
      setDefaultView('medication');
    } else if (weightEntries.length > 0) {
      setDefaultView('weight');
    }
  }, [medicationEntries, weightEntries, startWeight, goalWeight]);

  useEffect(() => {
    if (user) {
      setIsLoading(true);
      Promise.all([
        fetchUserProfile(setStartWeight, setGoalWeight, setFriendlyName, setUserHeight),
        fetchMedicationEntries(),
        fetchWeightEntries(),
        fetchMedications(setMedications),
        fetchInjectionLocations(setInjectionLocations)
      ]).then(() => {
        setIsLoading(false);
      });
    }
  }, [user, fetchMedicationEntries, fetchWeightEntries]);

  useEffect(() => {
    combineAndSortEntries();
  }, [medicationEntries, weightEntries, combineAndSortEntries]);  

  useEffect(() => {
    if (latestEntry?.weight && userHeight) {
      const weightInKg = latestEntry.weight * 0.453592;
      const heightInM = userHeight * 0.0254;
      const calculatedBmi = weightInKg / (heightInM * heightInM);
      setBmi(calculatedBmi.toFixed(1));
    }
  }, [latestEntry, userHeight]);  

  const handleSubmit = async (trackingType, newEntry, lastWeight) => {
    if (!user) return;
    let error;
    let payload = {
      user_id: user.id,
      weight: parseFloat(newEntry.weight),
    };
    
    const formatDateToUTC = (date) => {
      return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())).toISOString().split('T')[0];
    };
    
    if (trackingType === 'medication') {
      payload = {
        ...payload,
        medication_name: newEntry.medication_name,
        inject_date: formatDateToUTC(newEntry.inject_date),
        dosage: newEntry.dosage,
        injection_location: newEntry.injection_location,
      };
    } else {
      payload = {
        ...payload,
        weigh_date: formatDateToUTC(newEntry.weigh_date),
      };
    }
    
    const tableName = trackingType === 'medication' ? 'glp1_tracking' : 'weight_tracking';
    const { data: insertedData, error: insertError } = await supabase.from(tableName).insert([payload]).select();
    error = insertError;
    
    if (error) {
      console.log('error', error);
    } else {
      if (newEntry.feedPost) {
        await createWeightUpdatePost(user.id, newEntry.weight, lastWeight);
      }
  
      let updatedMedicationEntries = medicationEntries;
      let updatedWeightEntries = weightEntries;
    
      if (trackingType === 'medication') {
        updatedMedicationEntries = [insertedData[0], ...medicationEntries];
        setMedicationEntries(updatedMedicationEntries);
      } else {
        updatedWeightEntries = [insertedData[0], ...weightEntries];
        setWeightEntries(updatedWeightEntries);
      }
    
      const newLatestEntry = {
        ...insertedData[0],
        type: trackingType,
        date: trackingType === 'medication' ? insertedData[0].inject_date : insertedData[0].weigh_date
      };
    
      setLatestEntry(newLatestEntry);
    
      combineAndSortEntries();
    
      // Use lastWeight passed from the modal instead of newLatestEntry.weight
      generateCoachMessage(newEntry.weight, startWeight, lastWeight);
      checkForMilestones(newEntry.weight);
      setIsModalOpen(false);
    }
  };

  const handleDelete = async (id, entryType) => {
    const tableName = entryType === 'medication' ? 'glp1_tracking' : 'weight_tracking';
    const { error } = await supabase
      .from(tableName)
      .delete()
      .eq('id', id);

    if (error) {
      console.error(`Error deleting ${entryType} entry:`, error);
    } else {
      await Promise.all([fetchMedicationEntries(), fetchWeightEntries()]);
      combineAndSortEntries();
    }
  };

const generateCoachMessage = async (currentWeight, startWeight, lastWeight) => {
  const summary = await generateSummary(currentWeight, startWeight, lastWeight, friendlyName);
  if (summary) {
    setCoachMessage(summary);
  }
};

  const checkForMilestones = (currentWeight) => {
    const weightLost = startWeight - currentWeight;
    const goalAchievement = startWeight - goalWeight;
    const halfGoal = goalAchievement / 2;

    if (currentWeight <= goalWeight) {
      setShowConfetti(true);
      setConfettiMessage(`Congratulations, ${friendlyName}! You've reached your goal weight! 🎉`);
      setHalfwayMilestoneReached(true);
      setTimeout(() => {
        setShowConfetti(false);
        setConfettiMessage('');
      }, 10000);
    } else if (weightLost >= halfGoal && currentWeight > goalWeight && !halfwayMilestoneReached) {
      setShowConfetti(true);
      setConfettiMessage(`You're halfway there, ${friendlyName}! Keep it up! 💪`);
      setHalfwayMilestoneReached(true);
      setTimeout(() => {
        setShowConfetti(false);
        setConfettiMessage('');
      }, 10000);
    } else {
      setShowConfetti(false);
    }
  };

  const dismissCoachMessage = () => {
    setCoachMessage('');
  };

  const dismissSpecialMessage = () => {
    setSpecialMessage('');
  };

  const handleScroll = useCallback(() => {
    const currentScrollY = window.scrollY;
    setNavVisible(currentScrollY <= lastScrollY);
    setLastScrollY(currentScrollY);
  }, [lastScrollY]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  if (!user) {
    return <div>Please log in to view your GLP Tracker.</div>;
  }

  if (isLoading) {
    return <WeightSkeletonLoader isDarkMode={isDarkMode} />;
  }

  return (
    <div className="mb-4 relative pb-24">
      {showConfetti && <Confetti width={width} height={height} recycle={false} />}

      {showConfetti && (
        <div className="p-4 bg-green-500 text-white rounded shadow-md text-center mb-4 relative">
          <button onClick={() => setShowConfetti(false)} className="absolute top-2 right-2 text-white">
            <FontAwesomeIcon icon={faTimes} />
          </button>
          <p>{confettiMessage}</p>
        </div>
      )}

      {specialMessage && (
        <div className="p-4 bg-green-500 text-white rounded shadow-md text-center mb-4 relative">
          <button onClick={dismissSpecialMessage} className="absolute top-2 right-2 text-white">
            <FontAwesomeIcon icon={faTimes} />
          </button>
          <p>{specialMessage}</p>
        </div>
      )}

      {coachMessage && (
        <div className={`p-4 ${isDarkMode ? 'bg-[#131827] text-white' : 'bg-white text-gray-800'} rounded shadow-md text-center mb-4 relative`}>
          <button 
            onClick={dismissCoachMessage} 
            className={`absolute top-2 right-2 ${isDarkMode ? 'text-white' : 'text-gray-800'}`}
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>
          <h3 className="text-lg font-bold mb-2">Coach's Message</h3>
          <p>{coachMessage}</p>
        </div>
      )}

      <StatsSection 
        startWeight={startWeight}
        latestEntry={latestEntry}
        goalWeight={goalWeight}
        lossStats={lossStats}
        userHeight={userHeight}
        isDarkMode={isDarkMode}
        bmi={bmi}
      />

      <WeightChart 
        entries={combinedEntries}
        goalWeight={goalWeight}
        isDarkMode={isDarkMode}
      />

      <div className="mb-8"></div>

      {defaultView === 'medication' && (
        <EntriesTable 
          medicationEntries={medicationEntries}
          weightEntries={weightEntries}
          handleDelete={handleDelete}
          isDarkMode={isDarkMode}
        />
      )}

      <div className={`fixed ${navVisible ? 'bottom-16' : 'bottom-4'} left-0 right-0 flex justify-center pointer-events-none transition-all duration-300`}>
        <div className="pointer-events-auto z-10">
          <button
            onClick={() => setIsModalOpen(true)}
            className="bg-blue-500 text-white px-8 py-4 text-lg rounded-full hover:bg-blue-600"
          >
            <FontAwesomeIcon icon={faPlus} /> Add Entry
          </button>
        </div>
      </div>

      <AddEntryModal 
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSubmit={handleSubmit}
        medications={medications}
        injectionLocations={injectionLocations}
        isDarkMode={isDarkMode}
      />
    </div>
  );
};

export default GlpTracker;

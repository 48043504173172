import axios from 'axios';
import getConfig from '../config';

let api;

const initApi = async () => {
  const { API_URL, API_KEY } = await getConfig();
  
  api = axios.create({
    baseURL: API_URL,
    headers: {
      'X-API-Key': API_KEY
    }
  });
};

// Initialize the API
initApi();

// Wrap each API call with a check to ensure the API is initialized
const ensureApiInitialized = async (apiCall) => {
  if (!api) {
    await initApi();
  }
  return apiCall();
};

export const fetchGlobalFeed = (limit = 10, lastFetchedId = null) => 
  ensureApiInitialized(() => 
    api.get('/feed/global', { params: { limit, lastFetchedId } })
      .then(response => response.data)
      .catch(error => {
        console.error('Error fetching global feed:', error);
        throw error;
      })
  );

  export const fetchUserFeed = (userId, limit = 10, lastFetchedId = null) => 
  ensureApiInitialized(() => {
    return api.get('/feed/user', { params: { userId, limit, lastFetchedId } })
      .then(response => response.data)
      .catch(error => {
        console.error('Error fetching user feed:', error);
        throw error;
      });
  });

export const createPost = (userId, content, additionalData = {}) => 
  ensureApiInitialized(() => 
    api.post('/posts', { userId, content, ...additionalData })
      .then(response => response.data)
      .catch(error => {
        console.error('Error creating post:', error);
        throw error;
      })
  );

export const deletePost = (postId, userId) => 
  ensureApiInitialized(() => 
    api.delete(`/posts/${postId}`, { data: { userId } })
      .then(response => response.data)
      .catch(error => {
        console.error('Error deleting post:', error);
        throw error;
      })
  );

  export const addReaction = (activityId, reactionType, userId, content = '') => 
  ensureApiInitialized(() => 
    api.post('/reactions', { activityId, reactionType, userId, content })
      .then(response => {
        console.log('Add reaction response:', response.data);
        return response.data;
      })
      .catch(error => {
        console.error('Error adding reaction:', error);
        throw error;
      })
  );

  export const removeReaction = (reactionId) => 
  ensureApiInitialized(() => 
    api.delete(`/reactions/${reactionId}`)
      .then(response => {
        if (response.data && response.data.success) {
          return response.data;
        } else {
          throw new Error('Invalid response from remove reaction API');
        }
      })
      .catch(error => {
        console.error('Error removing reaction:', error);
        throw error;
      })
  );

  export const fetchReactions = (activityId, reactionType) => 
  ensureApiInitialized(() => 
    api.get('/reactions', { params: { activityId, reactionType } })
      .then(response => {
        if (Array.isArray(response.data)) {
          return response.data;
        } else {
          throw new Error('Invalid response from fetch reactions API');
        }
      })
      .catch(error => {
        console.error('Error fetching reactions:', error);
        throw error;
      })
  );

export const fetchFollowers = (userId) => 
  ensureApiInitialized(() => 
    api.get('/followers', { params: { userId } })
      .then(response => response.data)
      .catch(error => {
        console.error('Error fetching followers:', error);
        throw error;
      })
  );

export const fetchFollowing = (userId) => 
  ensureApiInitialized(() => 
    api.get('/following', { params: { userId } })
      .then(response => response.data)
      .catch(error => {
        console.error('Error fetching following:', error);
        throw error;
      })
  );

export const checkFollowStatus = (followerId, followedId) => 
  ensureApiInitialized(() => 
    api.get('/followStatus', { params: { followerId, followedId } })
      .then(response => response.data.isFollowing)
      .catch(error => {
        console.error('Error checking follow status:', error);
        throw error;
      })
  );

export const followUser = (followerId, followedId) => 
  ensureApiInitialized(() => 
    api.post('/follow', { followerId, followedId })
      .then(response => response.data)
      .catch(error => {
        console.error('Error following user:', error);
        throw error;
      })
  );

export const unfollowUser = (followerId, followedId) => 
  ensureApiInitialized(() => 
    api.delete('/unfollow', { data: { followerId, followedId } })
      .then(response => response.data)
      .catch(error => {
        console.error('Error unfollowing user:', error);
        throw error;
      })
  );
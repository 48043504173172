import React, { useState, useEffect, useContext, useCallback } from 'react';
import { ThemeContext } from '../../ThemeContext';
import { AuthContext } from '../../AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { fetchProfileImage } from '../../utils/imageUtils';
import { addReaction, fetchReactions } from '../../utils/getStreamApi';
import { supabase } from '../../supabaseClient';

const CommentSection = ({ postId, onNewComment }) => {
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [isExpanded, setIsExpanded] = useState(false);
  const { isDarkMode } = useContext(ThemeContext);
  const { user } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);

  const fetchComments = useCallback(async () => {
    try {
      setLoading(true);
      const fetchedComments = await fetchReactions(postId, 'comment');
      
      const commentsWithUserData = await Promise.all(fetchedComments.map(async (comment) => {
        // Fetch user data from your user database (e.g., Supabase)
        const { data: userData, error } = await supabase
          .from('users')
          .select('id, username, friendly_name, image_link')
          .eq('id', comment.user_id)
          .single();

        if (error) {
          console.error('Error fetching user data:', error);
          return comment;
        }

        const userInfo = userData || {
          id: comment.user_id,
          friendly_name: 'Anonymous User',
          username: 'anonymous',
          image_link: null
        };

        if (userInfo.image_link) {
          const imageUrl = await fetchProfileImage(userInfo.image_link);
          userInfo.imageUrl = imageUrl;
        }

        return { ...comment, user: userInfo };
      }));

      setComments(commentsWithUserData);
    } catch (error) {
      console.error('Error fetching comments:', error);
    } finally {
      setLoading(false);
    }
  }, [postId]);

  useEffect(() => {
    fetchComments();
  }, [fetchComments]);

  const handleSubmitComment = async (e) => {
    e.preventDefault();

    if (!user) {
      console.error("No authenticated user found");
      return;
    }

    try {
      const comment = await addReaction(postId, 'comment', user.id, newComment);
      
      const { data: userData, error } = await supabase
        .from('users')
        .select('id, username, friendly_name, image_link')
        .eq('id', user.id)
        .single();

      if (error) {
        console.error('Error fetching user data:', error);
        return;
      }

      const commentWithUser = {
        ...comment,
        user: userData,
        data: { text: newComment }
      };

      if (commentWithUser.user.image_link) {
        const imageUrl = await fetchProfileImage(commentWithUser.user.image_link);
        commentWithUser.user.imageUrl = imageUrl;
      }

      setComments(prevComments => [...prevComments, commentWithUser]);
      setNewComment('');
      setIsExpanded(false);
      if (onNewComment) onNewComment();
    } catch (error) {
      console.error('Error creating comment:', error);
    }
  };

  return (
    <div className="mt-4">
      <div className="space-y-4">
        {loading ? (
          <div className="animate-pulse">
            <div className="flex items-center space-x-2">
              <div className="w-8 h-8 rounded-full bg-gray-300"></div>
              <div className="flex flex-col space-y-2">
                <div className="w-24 h-4 bg-gray-300 rounded"></div>
                <div className="w-32 h-4 bg-gray-300 rounded"></div>
              </div>
            </div>
          </div>
        ) : (
          comments.map((comment) => (
            <div key={comment.id} className="flex items-start space-x-2 -mt-2">
              {comment.user?.imageUrl ? (
                <img
                  src={comment.user.imageUrl}
                  alt={comment.user?.friendly_name || comment.user?.username || 'User'}
                  className="w-8 h-8 rounded-full"
                />
              ) : (
                <FontAwesomeIcon icon={faUser} className="w-8 h-8" />
              )}
              <div className="flex flex-col items-start">
                <p className="font-bold">
                  {comment.user?.friendly_name || comment.user?.username || 'Anonymous User'}
                </p>
                <p>{comment.data?.text || 'No content'}</p>
              </div>
            </div>
          ))
        )}
      </div>

      <form onSubmit={handleSubmitComment} className="mt-2">
        <div className={`flex flex-col ${isExpanded ? 'space-y-2' : 'items-center space-x-2'}`}>
          <textarea
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
            placeholder="Write a comment..."
            className={`w-full p-2 rounded ${isDarkMode ? 'bg-gray-800 text-white' : 'bg-white text-gray-900'} ${isExpanded ? 'h-24' : 'h-10'}`}
            onFocus={() => setIsExpanded(true)}
            onBlur={() => !newComment && setIsExpanded(false)}
            rows={isExpanded ? 4 : 1}
          />
          {isExpanded && (
            <button
              type="submit"
              className={`self-end px-4 py-2 rounded ${isDarkMode ? 'bg-blue-600 hover:bg-blue-700' : 'bg-blue-500 hover:bg-blue-600'} text-white`}
            >
              Post
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

export default CommentSection;
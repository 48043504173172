import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../../supabaseClient';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { fetchProfileImage } from '../../utils/imageUtils';
import { fetchFollowers, fetchFollowing } from '../../utils/getStreamApi';

const Follows = ({ userId, type }) => {
  const [follows, setFollows] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchFollows = async () => {
      try {
        let userIds;
        if (type === 'followers') {
          userIds = await fetchFollowers(userId);
        } else {
          userIds = await fetchFollowing(userId);
        }

        // Fetch user details from Supabase
        const { data, error } = await supabase
          .from('users')
          .select('id, username, friendly_name, image_link, location, tagline')
          .in('id', userIds);

        if (error) throw error;

        // Fetch profile images
        const followsWithImages = await Promise.all(data.map(async (user) => {
          if (user.image_link) {
            const imageUrl = await fetchProfileImage(user.image_link);
            user.imageUrl = imageUrl;
          } else {
            user.imageUrl = '/images/default-avatar.png'; // Use default avatar if no image link
          }
          return user;
        }));

        setFollows(followsWithImages);
      } catch (error) {
        console.error('Error fetching follows:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchFollows();
  }, [userId, type]);

  const handleUserClick = (username) => {
    navigate(`/social/${username}`, { replace: true });
    window.location.reload();
  };

  return (
    <div className="mt-4">
      {loading ? (
        <p>Loading...</p>
      ) : follows.length === 0 ? (
        <p>No {type === 'followers' ? 'followers' : 'following'} yet.</p>
      ) : (
        follows.map((user) => (
          <div key={user.id} className="flex items-center mb-4 cursor-pointer" onClick={() => handleUserClick(user.username)}>
            <img src={user.imageUrl} alt={user.friendly_name} className="w-12 h-12 rounded-full mr-4" />
            <div>
              <p className="font-bold hover:underline">{user.friendly_name}</p>
              <p className="text-gray-500">@{user.username}</p>
              <p className="text-gray-500">
                <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-1" />
                {user.location || 'The Gym'}
              </p>
              <p className="text-gray-500">{user.tagline || 'I love fitness!'}</p>
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default Follows;

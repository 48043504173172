import React, { useState, useEffect, useContext } from 'react';
import { ThemeContext } from '../../ThemeContext';
import { AuthContext } from '../../AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { format, parseISO, isValid } from 'date-fns';
import { supabase } from '../../supabaseClient';
import WorkoutDetailsModal from './WorkoutDetailsModal';

const WorkoutHistory = ({ onClose }) => {
  const { isDarkMode } = useContext(ThemeContext);
  const { user } = useContext(AuthContext);
  const [workouts, setWorkouts] = useState([]);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [selectedWorkout, setSelectedWorkout] = useState(null);

  useEffect(() => {
    if (user) {
      fetchWorkouts();
    }
  }, [user, page]);

  const fetchWorkouts = async () => {
    const { data, error } = await supabase
      .from('workouts')
      .select(`
        *,
        workout_exercises (
          id,
          name
        )
      `)
      .eq('user_id', user.id)
      .order('start_time', { ascending: false })
      .range(page * 10, (page + 1) * 10 - 1);

    if (error) {
      console.error('Error fetching workouts:', error);
    } else {
      setWorkouts(data);
      setHasMore(data.length === 10);
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return 'No date provided';
    const date = parseISO(dateString);
    return isValid(date) ? format(date, 'EEEE, MMM d, yyyy - h:mma') : 'Invalid date';
  };

  const formatDuration = (seconds) => {
    const mins = Math.floor(seconds / 60);
    return `${mins} min`;
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 p-4">
      <div className={`relative w-full max-w-2xl h-full max-h-[90vh] ${isDarkMode ? 'bg-gray-800 text-white' : 'bg-white text-gray-900'} rounded-lg shadow-xl overflow-hidden flex flex-col`}>
        {/* Header */}
        <div className="flex justify-between items-center p-4">
          <h2 className="text-xl font-semibold">Workout History</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200">
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>

        {/* Content */}
        <div className="flex-1 overflow-auto p-4">
          {workouts.map((workout) => (
            <div 
              key={workout.id} 
              className="mb-4 p-4 rounded-lg cursor-pointer shadow-md hover:shadow-lg transition-shadow duration-200"
              onClick={() => setSelectedWorkout(workout)}
            >
              <h3 className="text-lg font-semibold mb-2">{workout.name || 'Unnamed Workout'}</h3>
              <p className="text-sm text-gray-500 dark:text-gray-400 mb-2">
                {formatDate(workout.start_time)}
              </p>
              <div className="flex justify-between text-sm">
                <span>Duration: {formatDuration(workout.duration)}</span>
                <span>Exercises: {workout.workout_exercises.length}</span>
                <span>Volume: {workout.total_volume || 0} lbs</span>
              </div>
            </div>
          ))}
        </div>

        {/* Pagination */}
        <div className="flex justify-between p-4">
          <button
            onClick={() => setPage(prev => Math.max(0, prev - 1))}
            disabled={page === 0}
            className="px-4 py-2 rounded bg-blue-500 text-white disabled:opacity-50"
          >
            <FontAwesomeIcon icon={faChevronLeft} /> Previous
          </button>
          <button
            onClick={() => setPage(prev => prev + 1)}
            disabled={!hasMore}
            className="px-4 py-2 rounded bg-blue-500 text-white disabled:opacity-50"
          >
            Next <FontAwesomeIcon icon={faChevronRight} />
          </button>
        </div>
      </div>

      {selectedWorkout && (
        <WorkoutDetailsModal 
          workout={selectedWorkout} 
          onClose={() => setSelectedWorkout(null)} 
        />
      )}
    </div>
  );
};

export default WorkoutHistory;
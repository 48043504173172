import { supabase } from '../supabaseClient';

export const fetchUserProfile = async (setStartWeight, setGoalWeight, setFriendlyName, setUserHeight) => {
  const { data: { user } } = await supabase.auth.getUser();
  const { data, error } = await supabase
    .from('users')
    .select('start_weight, goal_weight, friendly_name, height')
    .eq('id', user.id)
    .single();

  if (error) {
  } else {
    setStartWeight(data.start_weight);
    setGoalWeight(data.goal_weight);
    setFriendlyName(data.friendly_name);
    setUserHeight(data.height);
  }
};

export const fetchEntries = async (setEntries, setLatestEntry, calculateStats) => {
  const { data: { user } } = await supabase.auth.getUser();

  if (!user) {
    return;
  }

  const { data, error } = await supabase
    .from('glp1_tracking')
    .select('*')
    .eq('user_id', user.id)
    .order('inject_date', { ascending: false });

  if (error) {
    console.log('error', error);
  } else {
    setEntries(data);
    if (data.length > 0) {
      const lastEntry = data[0];
      setLatestEntry(lastEntry.weight);
    }
    calculateStats(data);
  }
};

export const fetchMedications = async (setMedications) => {
  const { data, error } = await supabase
    .from('medications')
    .select('*')
    .eq('is_active', true)
    .order('name');

  if (error) {
    console.log('error', error);
  } else {
    setMedications(data);
  }
};

export const fetchInjectionLocations = async (setInjectionLocations) => {
  const { data, error } = await supabase
    .from('injection_locations')
    .select('*')
    .eq('is_active', true)
    .order('name');

  if (error) {
    console.log('error', error);
  } else {
    setInjectionLocations(data);
  }
}; 

export const calculateStats = (data) => {
  const now = new Date();
  const past30Days = data.filter(entry => (now - new Date(entry.date)) / (1000 * 60 * 60 * 24) <= 30);
  const past90Days = data.filter(entry => (now - new Date(entry.date)) / (1000 * 60 * 60 * 24) <= 90);

  const initialWeight = data.length ? data[data.length - 1].weight : null;
  const latestWeight = data.length ? data[0].weight : null;
  const previousWeight = data.length > 1 ? data[1].weight : null;

  const calculateLoss = (entries) => {
    if (entries.length < 2) return 0;
    const startWeight = entries[entries.length - 1].weight;
    return parseFloat((startWeight - entries[0].weight).toFixed(1));
  };

  return {
    sinceLastWeighIn: latestWeight && previousWeight ? parseFloat((previousWeight - latestWeight).toFixed(1)) : 0,
    thirtyDays: calculateLoss(past30Days),
    ninetyDays: calculateLoss(past90Days),
    allTime: initialWeight && latestWeight ? parseFloat((initialWeight - latestWeight).toFixed(1)) : 0,
  };
};


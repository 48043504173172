// src/utils/notificationUtils.js

import { LocalNotifications } from '@capacitor/local-notifications';

export const setupGlp1Notification = async (day) => {
  try {
    const permissionStatus = await LocalNotifications.checkPermissions();
    if (permissionStatus.display !== 'granted') {
      await LocalNotifications.requestPermissions();
    }

    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const dayIndex = daysOfWeek.indexOf(day);
    
    if (dayIndex === -1) {
      console.error('Invalid day selected for notification');
      return false;
    }

    const now = new Date();
    const daysUntilNext = (dayIndex - now.getDay() + 7) % 7;
    const nextNotificationDate = new Date(now.getTime() + daysUntilNext * 24 * 60 * 60 * 1000);
    nextNotificationDate.setHours(6, 30, 0, 0); // Set to 6:30 AM

    await LocalNotifications.schedule({
      notifications: [
        {
          title: "GLP-1 Medication Reminder",
          body: "It's time for your weekly GLP-1 medication.",
          id: 1,
          schedule: { 
            at: nextNotificationDate,
            repeats: true,
            every: 'week',
            on: {
              weekday: dayIndex + 1
            }
          }
        }
      ]
    });

    return true;
  } catch (error) {
    console.error('Error scheduling notification:', error);
    return false;
  }
};

export const cancelGlp1Notification = async () => {
  try {
    await LocalNotifications.cancel({ notifications: [{ id: 1 }] });
    return true;
  } catch (error) {
    console.error('Error cancelling GLP-1 notification:', error);
    return false;
  }
};

export const checkNotificationPermissions = async () => {
  try {
    const permissionStatus = await LocalNotifications.checkPermissions();
    return permissionStatus.display === 'granted';
  } catch (error) {
    console.error('Error checking notification permissions:', error);
    return false;
  }
};
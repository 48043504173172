import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../../supabaseClient';
import { ThemeContext } from '../../ThemeContext';
import { AuthContext } from '../../AuthContext';
import WorkoutCard from './WorkoutCard';
import WorkoutHistory from './WorkoutHistory';
import WorkoutDetailsModal from './WorkoutDetailsModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDumbbell, faChartLine, faChevronRight, faClock, faPlus } from '@fortawesome/free-solid-svg-icons';

const ActiveWorkoutsList = ({ workouts, onClick }) => {
  const { isDarkMode } = useContext(ThemeContext);

  const containerClass = isDarkMode
    ? 'bg-gray-800 text-white'
    : 'bg-white text-gray-900';

  const borderClass = isDarkMode
    ? 'border-gray-700'
    : 'border-gray-200';

  const hoverClass = isDarkMode
    ? 'hover:bg-gray-700'
    : 'hover:bg-gray-100';

  return (
    <div className={`rounded-lg shadow-md ${containerClass}`}>
      {workouts.length > 0 && (
        <h2 className="text-xl font-bold p-4">Active Workout</h2>
      )}
      {workouts.map((workout, index) => (
        <div
          key={workout.id}
          className={`p-4 ${index !== 0 ? `border-t ${borderClass}` : ''} ${hoverClass} cursor-pointer transition duration-150 ease-in-out`}
          onClick={() => onClick(workout)}
        >
          <div className="flex justify-between items-center">
            <h3 className="text-lg font-semibold">{workout.name}</h3>
            <div className="flex items-center">
              <FontAwesomeIcon icon={faClock} className="mr-2" />
              <span>In progress</span>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

const ExerciseTracker = () => {
  const navigate = useNavigate();
  const { isDarkMode } = useContext(ThemeContext);
  const { user } = useContext(AuthContext);
  const [recentWorkouts, setRecentWorkouts] = useState([]);
  const [activeWorkouts, setActiveWorkouts] = useState([]);
  const [todaysActivity, setTodaysActivity] = useState(null);
  const [overallStatus, setOverallStatus] = useState([]);
  const [showWorkoutHistory, setShowWorkoutHistory] = useState(false);
  const [selectedWorkout, setSelectedWorkout] = useState(null);
  const [navVisible, setNavVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  useEffect(() => {
    if (user) {
      fetchRecentWorkouts();
      fetchActiveWorkouts();
      fetchTodaysActivity();
      fetchOverallStatus();
    }
  }, [user]);

  const fetchRecentWorkouts = async () => {
    const { data, error } = await supabase
      .from('workouts')
      .select(`
        *,
        workout_exercises (
          id,
          name
        )
      `)
      .eq('user_id', user.id)
      .order('start_time', { ascending: false })
      .limit(5);

    if (error) {
      console.error('Error fetching recent workouts:', error);
    } else {
      setRecentWorkouts(data);
    }
  };

  const fetchActiveWorkouts = async () => {
    const { data, error } = await supabase
      .from('workouts')
      .select('*')
      .eq('user_id', user.id)
      .is('end_time', null)
      .order('start_time', { ascending: false });

    if (error) {
      console.error('Error fetching active workouts:', error);
    } else {
      setActiveWorkouts(data);
    }
  };

  const fetchTodaysActivity = async () => {
    const today = new Date().toISOString().split('T')[0];
    const { data, error } = await supabase
      .from('workouts')
      .select(`
        *,
        workout_exercises (
          id,
          name,
          workout_sets (*)
        )
      `)
      .eq('user_id', user.id)
      .gte('start_time', today)
      .lt('start_time', new Date(new Date().setDate(new Date().getDate() + 1)).toISOString())
      .order('start_time', { ascending: false })
      .limit(1)
      .maybeSingle();

    if (error) {
      console.error('Error fetching today\'s activity:', error);
    } else if (data) {
      const totalVolume = data.workout_exercises.reduce((total, exercise) => {
        return total + exercise.workout_sets.reduce((exerciseTotal, set) => {
          return exerciseTotal + (set.weight || 0) * (set.reps || 0);
        }, 0);
      }, 0);

      setTodaysActivity({
        name: data.name,
        totalVolume: Math.round(totalVolume),
        exercises: data.workout_exercises,
        duration: data.duration
      });
    } else {
      setTodaysActivity(null);
    }
  };

  const fetchOverallStatus = async () => {
    const { count: totalWorkouts, error: workoutsError } = await supabase
      .from('workouts')
      .select('id', { count: 'exact' })
      .eq('user_id', user.id);
  
    if (workoutsError) {
      console.error('Error fetching total workouts:', workoutsError);
    }
  
    const { data: volumeData, error: volumeError } = await supabase
      .from('workouts')
      .select('total_volume')
      .eq('user_id', user.id);
  
    if (volumeError) {
      console.error('Error fetching all-time volume:', volumeError);
    }
  
    const allTimeVolume = volumeData
      ? volumeData.reduce((total, workout) => total + (workout.total_volume || 0), 0)
      : 0;
  
    setOverallStatus([
      {
        name: 'Total Workouts',
        value: totalWorkouts || 0,
        unit: '',
        change: '',
        changeColor: 'text-green-500',
        percentage: 100,
        icon: faDumbbell,
        iconBg: 'bg-orange-100',
        iconColor: 'text-orange-500',
      },
      {
        name: 'All Time Volume',
        value: Math.round(allTimeVolume),
        unit: 'lbs',
        change: '',
        changeColor: 'text-green-500',
        percentage: 100,
        icon: faChartLine,
        iconBg: 'bg-blue-100',
        iconColor: 'text-blue-500',
      },
    ]);
  };

  const handleContinueWorkout = (workout) => {
    navigate(`/workout/${workout.id}`, { state: { initialWorkout: workout } });
  };

  const handleScroll = useCallback(() => {
    const currentScrollY = window.scrollY;
    setNavVisible(currentScrollY <= lastScrollY);
    setLastScrollY(currentScrollY);
  }, [lastScrollY]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  const cardClass = isDarkMode ? 'bg-gray-800' : 'bg-white';

  const formatDuration = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins}:${secs.toString().padStart(2, '0')}`;
  };

  const cardBackgrounds = [
    '/images/workout-bg-1.jpg',
    '/images/workout-bg-2.jpg',
    '/images/workout-bg-3.jpg',
    '/images/workout-bg-4.jpg',
    '/images/workout-bg-5.jpg'
  ];

  const startWorkout = async () => {
    const { data, error } = await supabase
      .from('workouts')
      .insert({
        user_id: user.id,
        name: 'New Workout',
        start_time: new Date().toISOString(),
        duration: 0
      })
      .select()
      .single();

    if (error) {
      console.error('Error starting workout:', error);
    } else {
      fetchActiveWorkouts();
      navigate(`/workout/${data.id}`);
    }
  };

  return (
    <div className="mb-4 relative pb-24">
      {/* Active Workouts */}
      <div className="mb-6">
        <ActiveWorkoutsList workouts={activeWorkouts} onClick={handleContinueWorkout} />
      </div>

      {/* Recent Workouts */}
      <div className="mb-6">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold">Recent Workouts</h2>
          <button 
            onClick={() => setShowWorkoutHistory(true)} 
            className="text-blue-500"
          >
            See more <FontAwesomeIcon icon={faChevronRight} />
          </button>
        </div>
        <div className="flex overflow-x-auto pb-4">
          {recentWorkouts.map((workout, index) => (
            <WorkoutCard 
              key={workout.id} 
              workout={workout} 
              backgroundImage={cardBackgrounds[index % cardBackgrounds.length]}
              onClick={() => setSelectedWorkout(workout)}
            />
          ))}
        </div>
      </div>

      {/* Today's Activity */}
      <div className={`${cardClass} rounded-xl p-4 mb-6 shadow`}>
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold">Today's Activity</h2>
        </div>
        <div className="flex">
          <div className="w-1/3 bg-blue-400 rounded-lg p-4 text-white">
            <FontAwesomeIcon icon={faDumbbell} className="text-2xl mb-2" />
            <div className="text-2xl font-bold">{todaysActivity ? todaysActivity.totalVolume : 0}</div>
            <div>Total Volume (lbs)</div>
          </div>
          <div className="w-2/3 pl-4">
            {todaysActivity ? (
              <>
                <h3 className="font-bold mb-2">{todaysActivity.name}</h3>
                <p className="text-sm text-gray-500 mb-2">Duration: {formatDuration(todaysActivity.duration)}</p>
                {todaysActivity.exercises.map((exercise, index) => (
                  <div key={index} className="mb-2">
                    <div className="flex justify-between">
                      <div>
                        <div className="font-semibold">{exercise.name}</div>
                      </div>
                      <div className="font-semibold">
                        {exercise.workout_sets.length} sets
                      </div>
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <p className="text-gray-500">No workout recorded today</p>
            )}
          </div>
        </div>
      </div>

      {/* Overall Status */}
      <div className={`${cardClass} rounded-xl p-4 mb-6 shadow`}>
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold">Overall Status</h2>
        </div>
        <div className="space-y-4">
          {overallStatus.map((status, index) => (
            <div key={index} className="flex items-center">
              <div className={`w-10 h-10 rounded-full flex items-center justify-center ${status.iconBg}`}>
                <FontAwesomeIcon icon={status.icon} className={status.iconColor} />
              </div>
              <div className="ml-4 flex-grow">
                <div className="font-semibold">{status.name}</div>
                <div className="flex justify-between items-center">
                  <div className="text-xl font-bold">
                    {status.value} <span className="text-sm font-normal">{status.unit}</span>
                  </div>
                  <div className={`text-sm ${status.changeColor}`}>{status.change}</div>
                </div>
              </div>
              <div className="w-16 h-16 relative">
                <svg className="w-full h-full" viewBox="0 0 36 36">
                  <path
                    d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
                    fill="none"
                    stroke="#E0E0E0"
                    strokeWidth="3"
                  />
                  <path
                    d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
                    fill="none"
                    stroke="#4FD1C5"
                    strokeWidth="3"
                    strokeDasharray={`${status.percentage}, 100`}
                  />
                </svg>
                <div className="absolute inset-0 flex items-center justify-center text-sm font-bold">
                  {status.percentage}%
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Fixed bottom container for Start Workout Button */}
      <div className={`fixed ${navVisible ? 'bottom-16' : 'bottom-4'} left-0 right-0 flex justify-center pointer-events-none transition-all duration-300`}>
        <div className="pointer-events-auto z-10">
          {activeWorkouts.length === 0 && (
            <button
              onClick={startWorkout}
              className="bg-blue-500 text-white px-8 py-4 text-lg rounded-full hover:bg-blue-600"
            >
              <FontAwesomeIcon icon={faPlus} /> Start Workout
            </button>
          )}
        </div>
      </div>

      {/* Workout History Modal */}
      {showWorkoutHistory && (
        <WorkoutHistory onClose={() => setShowWorkoutHistory(false)} />
      )}

      {/* Workout Details Modal */}
      {selectedWorkout && (
        <WorkoutDetailsModal
          workout={selectedWorkout}
          onClose={() => setSelectedWorkout(null)}
        />
      )}
    </div>
  );
};

export default ExerciseTracker; 
import React, { useState, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV, faCheckSquare, faTrash } from '@fortawesome/free-solid-svg-icons';
import { supabase } from '../../supabaseClient';
import { ThemeContext } from '../../ThemeContext';

const ExerciseBlock = ({ exercise, workoutId, onDeleteExercise }) => {
  const [sets, setSets] = useState(() => {
    if (exercise.workout_sets && exercise.workout_sets.length > 0) {
      return exercise.workout_sets;
    } else {
      return [{
        id: 'temp-id',
        set_number: 1,
        weight: null,
        reps: null,
        is_completed: false
      }];
    }
  });
  const [showMenu, setShowMenu] = useState(false);
  const { isDarkMode } = useContext(ThemeContext);

  const addSet = async () => {
    const newSetNumber = sets.length + 1;
    const lastSet = sets[sets.length - 1] || { weight: null, reps: null };
    
    if (sets.length === 1 && sets[0].id === 'temp-id') {
      const { data, error } = await supabase
        .from('workout_sets')
        .insert({
          workout_exercise_id: exercise.id,
          set_number: 1,
          weight: lastSet.weight,
          reps: lastSet.reps,
          is_completed: false
        })
        .select()
        .single();

      if (error) {
        console.error('Error adding set:', error);
      } else {
        setSets([data]);
      }
    } else {
      const { data, error } = await supabase
        .from('workout_sets')
        .insert({
          workout_exercise_id: exercise.id,
          set_number: newSetNumber,
          weight: lastSet.weight,
          reps: lastSet.reps,
          is_completed: false
        })
        .select()
        .single();

      if (error) {
        console.error('Error adding set:', error);
      } else {
        setSets([...sets, data]);
      }
    }
  };

  const updateSet = async (setId, updates) => {
    const processedUpdates = Object.keys(updates).reduce((acc, key) => {
      if (updates[key] === "") {
        acc[key] = null;
      } else if (key === "weight" || key === "reps") {
        acc[key] = Number(updates[key]) || null;
      } else {
        acc[key] = updates[key];
      }
      return acc;
    }, {});
  
    setSets(sets.map(set => set.id === setId ? { ...set, ...processedUpdates } : set));

    // Debounced update to database
    const debouncedUpdate = setTimeout(async () => {
      const { error } = await supabase
        .from('workout_sets')
        .update(processedUpdates)
        .eq('id', setId);
    
      if (error) {
        console.error('Error updating set:', error);
      }
    }, 500);

    return () => clearTimeout(debouncedUpdate);
  };

  const themeClass = isDarkMode ? 'bg-gray-800 text-white' : 'bg-white text-gray-900';
  const inputClass = isDarkMode ? 'text-white bg-transparent' : 'text-gray-900 bg-transparent';
  const buttonClass = isDarkMode ? 'bg-gray-700 text-white hover:bg-gray-600' : 'bg-gray-200 text-gray-900 hover:bg-gray-300';
  const completedRowClass = isDarkMode ? 'bg-gray-700' : 'bg-gray-100';
  const placeholderClass = isDarkMode ? 'placeholder-gray-500' : 'placeholder-gray-400';

  return (
    <div className={`mb-6 ${themeClass} w-full rounded-lg shadow-md p-4`}>
      <div className="flex justify-between items-center mb-2">
        <h2 className="text-2xl font-bold">{exercise.name}</h2>
        <div className="relative">
          <FontAwesomeIcon 
            icon={faEllipsisV} 
            className="text-gray-500 cursor-pointer"
            onClick={() => setShowMenu(!showMenu)}
          />
          {showMenu && (
            <div className="absolute right-0 mt-2 py-2 w-48 bg-white rounded-md shadow-xl z-20">
              <button
                className="block px-4 py-2 text-sm capitalize text-gray-700 hover:bg-blue-500 hover:text-white"
                onClick={() => {
                  onDeleteExercise(exercise.id);
                  setShowMenu(false);
                }}
              >
                <FontAwesomeIcon icon={faTrash} className="mr-2" />
                Delete Exercise
              </button>
            </div>
          )}
        </div>
      </div>
      <p className={`${isDarkMode ? 'text-gray-400' : 'text-gray-600'} mb-4`}>Focus on your sets</p>
      <table className="w-full">
        <thead>
          <tr className={`${isDarkMode ? 'text-gray-400' : 'text-gray-600'} text-sm uppercase`}>
            <th className="text-left py-2 pl-2 w-[12%]">Set</th>
            <th className="text-left py-2 pl-6 w-[26%]">Prev</th>
            <th className="text-left py-2 w-[15%]">Lbs</th>
            <th className="text-left py-2 w-[20%]">Reps</th>
            <th className="w-[20%] text-center pr-4">
              <FontAwesomeIcon icon={faCheckSquare} className="text-xl" />
            </th>
          </tr>
        </thead>
        <tbody>
          {sets.map((set, index) => (
            <tr 
              key={set.id} 
              className={`${set.is_completed ? completedRowClass : ''}`}
            >
              <td className="py-3 pl-4">{set.set_number}</td>
              <td className={`${isDarkMode ? 'text-gray-500' : 'text-gray-600'} py-3 pl-4`}>{set.weight}lbs x {set.reps}</td>
              <td className="py-3">
                <input
                  type="number"
                  value={set.weight ?? ''}
                  onChange={(e) => updateSet(set.id, { weight: e.target.value })}
                  className={`w-full ${inputClass} ${placeholderClass} p-2 rounded`}
                  min="0"
                  step="0.5"
                  placeholder="50 lbs"
                />
              </td>
              <td className="py-3">
                <input
                  type="number"
                  value={set.reps ?? ''}
                  onChange={(e) => updateSet(set.id, { reps: e.target.value })}
                  className={`w-full ${inputClass} ${placeholderClass} p-2 rounded`}
                  min="0"
                  step="1"
                  placeholder="8 reps"
                />
              </td>
              <td className="py-3 text-center pr-4">
                <FontAwesomeIcon 
                  icon={faCheckSquare} 
                  className={`text-2xl cursor-pointer ${set.is_completed ? 'text-green-500' : 'text-gray-400'}`}
                  onClick={() => updateSet(set.id, { is_completed: !set.is_completed })}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <button
        onClick={addSet}
        className={`mt-4 w-full ${buttonClass} py-3 rounded text-center transition duration-300 ease-in-out`}
      >
        + Add Set
      </button>
    </div>
  );
};

export default ExerciseBlock;
import axios from 'axios';
import getConfig from '../config';

let api;

const initApi = async () => {
  const { API_URL, API_KEY } = await getConfig();
  
  api = axios.create({
    baseURL: API_URL,
    headers: {
      'X-API-Key': API_KEY
    }
  });
};

// Initialize the API
initApi();

export const searchUnsplashPhotos = async (query, page = 1) => {
  try {
    const response = await api.get('/search', {
      params: { query, page }
    });
    return response.data.results;
  } catch (error) {
    console.error('Error searching Unsplash photos:', error);
    return [];
  }
};
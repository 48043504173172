import React, { useState, useRef, useEffect, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faSyringe, faWeight, faMapMarkerAlt, faTrash, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

const EntriesTable = ({ medicationEntries, weightEntries, handleDelete, isDarkMode }) => {
  const [displayedEntries, setDisplayedEntries] = useState(5);
  const [activeTable, setActiveTable] = useState('weight');
  const [isLoading, setIsLoading] = useState(true);
  const tableRef = useRef(null);
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);

  const combineAndSortEntries = useCallback(() => {
    const combinedEntries = [
      ...medicationEntries.map(entry => ({ ...entry, type: 'medication', date: new Date(entry.inject_date) })),
      ...weightEntries.map(entry => ({ ...entry, type: 'weight', date: new Date(entry.weigh_date) }))
    ];
    return combinedEntries.sort((a, b) => b.date - a.date);
  }, [medicationEntries, weightEntries]);

  useEffect(() => {

    if (medicationEntries.length > 0 || weightEntries.length > 0) {
      setIsLoading(false);
      
      if (medicationEntries.length > 0) {
        setActiveTable('medication');
      } else {
        setActiveTable('weight');
      }
    }
  }, [medicationEntries, weightEntries]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return `${date.getUTCMonth() + 1}/${date.getUTCDate()}/${date.getUTCFullYear().toString().substr(-2)}`;
  };  

  const loadMoreEntries = () => {
    setDisplayedEntries(prev => prev + 5);
  };

  const handleSwipe = useCallback(() => {
    if (touchStart - touchEnd > 75) {
      setActiveTable(prevTable => prevTable === 'medication' ? 'weight' : 'medication');
    }
    if (touchStart - touchEnd < -75) {
      setActiveTable(prevTable => prevTable === 'medication' ? 'weight' : 'medication');
    }
  }, [touchStart, touchEnd]);

  useEffect(() => {
    if (touchStart && touchEnd) handleSwipe();
  }, [touchStart, touchEnd, handleSwipe]);

  const onTouchStart = (e) => {
    setTouchEnd(null);
    setTouchStart(e.targetTouches[0].clientX);
  };

  const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX);

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    handleSwipe();
    setTouchStart(null);
    setTouchEnd(null);
  };

  const renderTable = useCallback((entries, isMedication) => {
    const sortedEntries = combineAndSortEntries();

    return (
      <table className={`w-full text-xs text-left ${isDarkMode ? 'text-gray-300' : 'text-gray-700'} mt-6`}>
        <thead className="uppercase">
          <tr>
            <th className="px-2 py-2 text-center"><FontAwesomeIcon icon={faCalendarAlt} /></th>
            {isMedication && <th className="px-2 py-2 text-center"><FontAwesomeIcon icon={faSyringe} /></th>}
            <th className="px-2 py-2 text-center"><FontAwesomeIcon icon={faWeight} /></th>
            {isMedication && <th className="px-2 py-2 text-center"><FontAwesomeIcon icon={faMapMarkerAlt} /></th>}
            <th className="px-2 py-2 text-center"><FontAwesomeIcon icon={faTrash} /></th>
          </tr>
        </thead>
        <tbody>
          {entries.slice(0, displayedEntries).map((entry, index) => {
            const entryIndex = sortedEntries.findIndex(e => 
              (isMedication ? e.id === entry.id && e.type === 'medication' : e.id === entry.id && e.type === 'weight')
            );
            const nextWeightEntry = sortedEntries.slice(entryIndex + 1).find(e => e.weight !== undefined);
            const weightChange = nextWeightEntry ? entry.weight - nextWeightEntry.weight : 0;
            const weightClass = weightChange > 0 ? 'text-red-500' : weightChange < 0 ? 'text-green-500' : '';

            return (
              <tr key={entry.id} className={`${isDarkMode ? 'border-gray-700' : 'border-gray-200'} border-b`}>
                <td className="px-2 py-2 whitespace-nowrap text-center">{formatDate(isMedication ? entry.inject_date : entry.weigh_date)}</td>
                {isMedication && (
                  <td className="px-2 py-2 whitespace-nowrap text-center">
                    {entry.medication_name}<br />{entry.dosage} mg
                  </td>
                )}
                <td className={`px-2 py-2 whitespace-nowrap text-center ${weightClass}`}>
                  {entry.weight} (lbs)
                </td>
                {isMedication && <td className="px-2 py-2 whitespace-nowrap text-center">{entry.injection_location}</td>}
                <td className="px-2 py-2 text-center">
                  <button onClick={() => handleDelete(entry.id, isMedication ? 'medication' : 'weight')} className="text-red-500 hover:text-red-700">
                    <FontAwesomeIcon icon={faTrash} />
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }, [combineAndSortEntries, displayedEntries, isDarkMode, handleDelete]);

  return (
    <div 
      className="overflow-x-auto"
      ref={tableRef}
      onTouchStart={onTouchStart}
      onTouchMove={onTouchMove}
      onTouchEnd={onTouchEnd}
    >      
      {isLoading ? (
        <div className={`text-center ${isDarkMode ? 'text-gray-400' : 'text-gray-600'} py-4`}>Loading...</div>
      ) : medicationEntries.length === 0 && weightEntries.length === 0 ? (
        <div className={`text-center ${isDarkMode ? 'text-gray-400' : 'text-gray-600'} py-4`}>Nothing here yet! Add your first entry.</div>
      ) : (
        <>
          {medicationEntries.length > 0 && weightEntries.length > 0 && (
            <div className="flex justify-between items-center mb-4 px-4">
              <FontAwesomeIcon 
                icon={faChevronLeft} 
                onClick={() => setActiveTable('medication')}
                className={`cursor-pointer text-xl mr-2 ${
                  activeTable === 'medication' 
                    ? isDarkMode ? 'text-blue-400' : 'text-blue-600'
                    : isDarkMode ? 'text-gray-400' : 'text-gray-600'
                }`}
              />
              <div className="flex-grow text-center">
                <span className={`font-medium ${isDarkMode ? 'text-white' : 'text-gray-800'}`}>
                  {activeTable === 'medication' ? 'Medication Tracking' : 'Weight Tracking'}
                </span>
              </div>
              <FontAwesomeIcon 
                icon={faChevronRight} 
                onClick={() => setActiveTable('weight')}
                className={`cursor-pointer text-xl ml-2 ${
                  activeTable === 'weight' 
                    ? isDarkMode ? 'text-blue-400' : 'text-blue-600'
                    : isDarkMode ? 'text-gray-400' : 'text-gray-600'
                }`}
              />
            </div>
          )}
          
          {medicationEntries.length > 0 && (activeTable === 'medication' || weightEntries.length === 0) && renderTable(medicationEntries, true)}
          {weightEntries.length > 0 && (activeTable === 'weight' || medicationEntries.length === 0) && renderTable(weightEntries, false)}
          
          {(medicationEntries.length > displayedEntries || weightEntries.length > displayedEntries) && (
            <div className="text-left mt-4 load-more-link">
                <button
                onClick={loadMoreEntries}
                className="text-blue-500 hover:underline cursor-pointer bg-transparent border-none"
                >
                Load More
                </button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default EntriesTable;
import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { supabase } from '../../supabaseClient';
import { ThemeContext } from '../../ThemeContext';
import { AuthContext } from '../../AuthContext';
import { usePostContext } from '../../PostContext';
import PostCard from './PostCard';
import WorkoutDetailsModal from '../ExerciseTracker/WorkoutDetailsModal'; // Update this line
import { fetchProfileImage } from '../../utils/imageUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faPlus, faEdit } from '@fortawesome/free-solid-svg-icons';
import BannerImageSearch from './BannerImageSearch';
import Modal from 'react-modal';
import Follows from './Follows';
import { 
  fetchUserFeed, 
  checkFollowStatus, 
  followUser, 
  unfollowUser, 
  deletePost,
  fetchFollowers,
  fetchFollowing
} from '../../utils/getStreamApi';
import InfiniteScroll from 'react-infinite-scroll-component';

const UserProfile = () => {
  const { username } = useParams();
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [posts, setPosts] = useState([]);
  const [isFollowing, setIsFollowing] = useState(false);
  const [followersCount, setFollowersCount] = useState(0);
  const [followingCount, setFollowingCount] = useState(0);
  const { isDarkMode } = useContext(ThemeContext);
  const { user: currentUser } = useContext(AuthContext);
  const { deletedPosts, addDeletedPost } = usePostContext();
  const [imageUrl, setImageUrl] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeTab, setActiveTab] = useState('home');
  const [hasMore, setHasMore] = useState(true);
  const [lastFetchedId, setLastFetchedId] = useState(null);
  const postsPerPage = 5;
  const [selectedWorkout, setSelectedWorkout] = useState(null);

  useEffect(() => {
    setActiveTab('home');
    setPosts([]);
    setLastFetchedId(null);
    setHasMore(true);
    setUser(null);
    setImageUrl(null);
    setIsFollowing(false);
    setFollowersCount(0);
    setFollowingCount(0);
  }, [username]);

  const fetchUserPosts = useCallback(async (userId) => {
    try {
      const data = await fetchUserFeed(userId, postsPerPage, lastFetchedId);

      if (data.length < postsPerPage) {
        setHasMore(false);
      }

      if (data.length > 0) {
        const filteredData = data.filter(post => !deletedPosts.includes(post.id));
        setLastFetchedId(filteredData[filteredData.length - 1].id);
        setPosts(prevPosts => {
          const newPostsMap = new Map([...prevPosts, ...filteredData].map(post => [post.id, post]));
          return Array.from(newPostsMap.values());
        });
      }
    } catch (error) {
      console.error('Error fetching user posts:', error);
    }
  }, [lastFetchedId, postsPerPage, deletedPosts]);

  const fetchFollowCounts = useCallback(async (userId) => {
    try {
      const followers = await fetchFollowers(userId);
      const following = await fetchFollowing(userId);
      setFollowersCount(followers.length);
      setFollowingCount(following.length);
    } catch (error) {
      console.error('Error fetching follow counts:', error);
    }
  }, []);

  const fetchUserData = useCallback(async () => {
    const { data, error } = await supabase
      .from('users')
      .select('*')
      .eq('username', username)
      .single();

    if (error) {
      console.error('Error fetching user data:', error);
    } else {
      setUser(data);
      if (data.image_link) {
        fetchProfileImage(data.image_link).then(setImageUrl);
      }
      fetchUserPosts(data.id);
      fetchFollowCounts(data.id);
    }
  }, [username, fetchUserPosts, fetchFollowCounts]);

  const checkIfFollowing = useCallback(async () => {
    if (!user || !currentUser) return;

    try {
      const isFollowing = await checkFollowStatus(currentUser.id, user.id);
      setIsFollowing(isFollowing);
    } catch (error) {
      console.error('Error checking follow status:', error);
    }
  }, [currentUser, user]);

  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);

  useEffect(() => {
    if (user && currentUser && user.id !== currentUser.id) {
      checkIfFollowing();
    }
  }, [user, currentUser, checkIfFollowing]);

  const handleFollow = async () => {
    if (!currentUser || !user) return;

    try {
      if (isFollowing) {
        await unfollowUser(currentUser.id, user.id);
        setIsFollowing(false);
        setFollowersCount(followersCount - 1);
      } else {
        await followUser(currentUser.id, user.id);
        setIsFollowing(true);
        setFollowersCount(followersCount + 1);
      }
    } catch (error) {
      console.error('Error updating follow status:', error);
    }
  };

  const handleBannerSelect = (bannerUrl) => {
    setUser((prevUser) => ({ ...prevUser, coverPhoto: bannerUrl }));
    setIsModalOpen(false);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const loadMorePosts = () => {
    if (user) {
      fetchUserPosts(user.id);
    }
  };

  const handleDeletePost = async (postId) => {
    try {
      await deletePost(postId, currentUser.id);
      setPosts(prevPosts => prevPosts.filter(post => post.id !== postId));
      addDeletedPost(postId);
    } catch (error) {
      console.error('Error deleting post:', error);
    }
  };

  const handleWorkoutClick = (workoutData) => {
    if (workoutData && workoutData.id) {
      setSelectedWorkout(workoutData);
    } else {
      console.error('Invalid workout data or missing ID');
    }
  };

  const closeWorkoutDetails = () => {
    setSelectedWorkout(null);
  };

  if (!user || !currentUser) {
    return <div>Loading...</div>;
  }

  return (
    <div className={`${isDarkMode ? 'bg-gray-900 text-white' : 'bg-white text-gray-900'} min-h-screen`}>
      <div className="relative w-full h-48">
        <div 
          className="absolute top-0 left-0 w-full h-full bg-gray-300"
          style={{
            backgroundImage: user.coverPhoto ? `url(${user.coverPhoto})` : `url('/images/default-cover.jpg')`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        >
          {currentUser.id === user.id && (
            <button 
              onClick={openModal} 
              className="absolute top-0 right-0 bg-black bg-opacity-50 text-white p-2 m-2 rounded cursor-pointer"
            >
              Change Cover Photo
            </button>
          )}
        </div>
        <img
          src={imageUrl || '/images/default-avatar.png'}
          alt={user.friendly_name}
          className="absolute -bottom-16 left-4 w-24 h-24 rounded-full border-4 border-white object-cover"
        />
      </div>
      <div className="mt-20 px-4">
        <div className="flex justify-between items-start">
          <div>
            <h1 className="text-3xl font-bold">{user.friendly_name}</h1>
            <p className="text-gray-500">@{user.username}</p>
          </div>
          {currentUser.id === user.id ? (
          <button
            onClick={() => navigate('/settings')}
            className={`border border-blue-500 text-blue-500 px-4 py-2 rounded ${isDarkMode ? 'bg-blue-600 hover:bg-blue-700' : 'bg-blue-500 hover:bg-blue-600'} text-white focus:outline-none`}
          >
            <FontAwesomeIcon icon={faEdit} className="mr-2" />
            Edit Profile
          </button>
          ) : (
            <button 
              className={`px-4 py-2 rounded ${
                isFollowing ? 'bg-gray-500 hover:bg-gray-600' : 'bg-blue-500 hover:bg-blue-600'
              } text-white`}
              onClick={handleFollow}
            >
              {isFollowing ? 'Unfollow' : 'Follow'}
            </button>
          )}
        </div>
        <p className="mt-2">{user.tagline || 'Fitness Lover!'}</p>
        <div className="flex items-center justify-between mt-2 text-gray-500">
          <div className="flex items-center">
            <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-2" />
            <span>{user.location || 'The Gym'}</span>
          </div>
          <div>
            <span className="font-bold">{followingCount}</span> Following{' '}
            <span className="font-bold">{followersCount}</span> Followers
          </div>
        </div>
        <div className="mt-4 border-b">
          <button 
            className={`px-4 py-2 font-semibold ${activeTab === 'home' ? 'text-blue-500 border-b-2 border-blue-500' : 'text-gray-500'}`} 
            onClick={() => setActiveTab('home')}
          >
            Home
          </button>
          <button 
            className={`px-4 py-2 font-semibold ${activeTab === 'followers' ? 'text-blue-500 border-b-2 border-blue-500' : 'text-gray-500'}`} 
            onClick={() => setActiveTab('followers')}
          >
            Followers
          </button>
          <button 
            className={`px-4 py-2 font-semibold ${activeTab === 'following' ? 'text-blue-500 border-b-2 border-blue-500' : 'text-gray-500'}`} 
            onClick={() => setActiveTab('following')}
          >
            Following
          </button>
        </div>
      </div>
      <div className="mt-4 px-4 space-y-4">
        {activeTab === 'home' && (
          posts.length > 0 ? (
            <InfiniteScroll
              dataLength={posts.length}
              next={loadMorePosts}
              hasMore={hasMore}
              loader={<h4>Loading...</h4>}
              endMessage={<p>No more posts to load.</p>}
            >
            {posts.map((post) => (
              <PostCard
                key={post.id}
                post={post}
                onDelete={handleDeletePost}
                onWorkoutClick={handleWorkoutClick}
              />
            ))}
            </InfiniteScroll>
          ) : (
            <div className="text-center py-8">
              <p className="text-xl mb-4">No posts yet!</p>
              {currentUser.id === user.id && (
                <button
                  onClick={() => navigate('/glptracker')}
                  className="bg-blue-500 hover:bg-blue-600 text-white px-6 py-2 rounded-full flex items-center justify-center mx-auto"
                >
                  <FontAwesomeIcon icon={faPlus} className="mr-2" />
                  Start Tracking
                </button>
              )}
            </div>
          )
        )}
        {activeTab === 'followers' && <Follows userId={user.id} type="followers" />}
        {activeTab === 'following' && <Follows userId={user.id} type="following" />}
      </div>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Change Cover Photo"
        className={`${isDarkMode ? 'bg-gray-900' : 'bg-gray-100'} p-6 rounded-lg shadow-lg w-full max-w-3xl mx-auto mt-20 ${isDarkMode ? 'text-white' : 'text-gray-900'} relative overflow-y-auto max-h-full`}
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-start pt-20"
      >
        <BannerImageSearch onBannerSelect={handleBannerSelect} />
      </Modal>

      <Modal
        isOpen={selectedWorkout !== null}
        onRequestClose={closeWorkoutDetails}
        contentLabel="Workout Details"
        className={`${isDarkMode ? 'bg-gray-900' : 'bg-gray-100'} p-6 rounded-lg shadow-lg w-full max-w-3xl mx-auto mt-20 ${isDarkMode ? 'text-white' : 'text-gray-900'} relative overflow-y-auto max-h-full`}
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-start pt-20"
      >
        {selectedWorkout && (
          <WorkoutDetailsModal
            workout={selectedWorkout}
            onClose={() => setSelectedWorkout(null)}
          />
        )}
      </Modal>
    </div>
  );
};

export default UserProfile;
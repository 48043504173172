import React from 'react';

const WeightSkeletonLoader = ({ isDarkMode }) => {
  const bgColor = isDarkMode ? 'bg-gray-700' : 'bg-gray-200';
  const pulseAnimation = 'animate-pulse';

  return (
    <div className="mb-4">
      {/* Stats Section Skeleton */}
      <div className={`${bgColor} ${pulseAnimation} h-40 mb-4 rounded`}></div>

      {/* Weight Chart Skeleton */}
      <div className={`${bgColor} ${pulseAnimation} h-64 mb-8 rounded`}></div>

      {/* Entries Table Skeleton */}
      <div className={`${bgColor} ${pulseAnimation} h-96 rounded`}></div>
    </div>
  );
};

export default WeightSkeletonLoader;
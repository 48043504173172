// src/config.js
import { Capacitor } from '@capacitor/core';
import { Preferences } from '@capacitor/preferences';

const getConfig = async () => {
    let API_URL, API_KEY;
  
    if (Capacitor.isNativePlatform()) {
      const urlResult = await Preferences.get({ key: 'API_URL' });
      const keyResult = await Preferences.get({ key: 'API_KEY' });
      API_URL = urlResult.value;
      API_KEY = keyResult.value;
    } else {
      API_URL = process.env.REACT_APP_API_URL;
      API_KEY = process.env.REACT_APP_API_KEY;
    }
    return { API_URL, API_KEY };
  };

export default getConfig;
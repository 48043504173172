import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDumbbell, faClock } from '@fortawesome/free-solid-svg-icons';

const WorkoutCard = ({ workout, backgroundImage, onClick }) => {
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
  };

  const formatDuration = (seconds) => {
    const mins = Math.floor(seconds / 60);
    return `${mins} min`;
  };

  return (
    <div 
      className="flex-shrink-0 w-64 h-40 rounded-xl overflow-hidden mr-4 relative cursor-pointer"
      onClick={onClick}
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center'
      }}
    >
      <div className="absolute inset-0 bg-black bg-opacity-50 p-4 flex flex-col justify-between text-white">
        <div>
          <h3 className="text-xl font-bold">{workout.name || 'Workout'}</h3>
          <p className="text-sm opacity-80">{formatDate(workout.start_time)}</p>
        </div>
        <div className="flex justify-between items-end">
          <div className="flex items-center">
            <FontAwesomeIcon icon={faDumbbell} className="mr-2" />
            <span>{workout.workout_exercises ? workout.workout_exercises.length : 0} Exercises</span>
          </div>
          <div className="flex items-center">
            <FontAwesomeIcon icon={faClock} className="mr-2" />
            <span>{formatDuration(workout.duration || 0)}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkoutCard;